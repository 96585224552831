import { Button, Card, Container, useTheme } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import React, { useContext, useEffect } from 'react';

import { ButtonRow, Loading, TextBubble } from '..';
import registration from '../../../../settings/registration.json';
import { UserContext } from '../../contexts';
import { useMemdbRequest } from '../../hooks';
import {
  birthday as birthdayField,
  city,
  DateFieldProps,
  email,
  firstname,
  lastname,
  MemberFields,
  password,
  phone as phoneField,
  sex,
  street,
  TextFieldProps,
  useMemberFields,
  zipcode,
} from '../fields/MemberFields';

export interface RegCreateAccountProps {
  member: Values;
  buttons: React.ReactNode;
}

const maxDate = `${
  new Date().getFullYear() - registration.minCreateAccountAge
}-12-31`;
const birthday: DateFieldProps = {
  ...birthdayField,
  props: {
    ...birthdayField.props,
    maxDate,
  },
  schema: {
    ...birthdayField.schema,
    hard: birthdayField.schema.hard?.max(
      // @ts-ignore
      maxDate,
      'Valt datumet måste vara innan ${max}',
    ),
  },
};

const phone: TextFieldProps = {
  props: {
    ...phoneField.props,
    label: 'Mobilnummer',
  },
  schema: {
    ...phoneField.schema,
    hard:
      phoneField.schema.hard &&
      phoneField.schema.hard.test(
        'test-phonenumber',
        'Du måste ange ett korrekt svenskt mobilnummer',
        (value) => value && parsePhoneNumber(value, 'SE').isValid(),
      ),
  },
};

const obj = {
  firstname,
  lastname,
  birthday,
  sex,
  street,
  zipcode,
  city,
  phone,
  email,
  password,
};
const fields = [
  [firstname, lastname, birthday, sex, street, zipcode, city, phone, email],
  [password],
];

type Values = { [P in keyof typeof obj]?: string | number | Date | null };

export const RegCreateAccount: React.FC<RegCreateAccountProps> = ({
  member,
  buttons,
}) => {
  const { spacing } = useTheme();
  const { request, isLoading, errorMessage } = useMemdbRequest();
  const { setToken } = useContext(UserContext);

  const {
    values,
    errors,
    setErrors,
    validate,
    onChange,
    onDateChange,
    onBlur,
  } = useMemberFields(member, obj);

  if (errorMessage) alert(errorMessage); // todo

  useEffect(() => {
    validate(values, 'medium').then(({ errors: _errors }) =>
      setErrors(_errors),
    );
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const submitValues: typeof values = {
      firstname: '',
      lastname: '',
      birthday: '',
      sex: -1,
      street: '',
      zipcode: -1,
      city: '',
      phone: '',
      email: '',
      password: '',

      ...values,
    };
    validate(submitValues, 'hard').then(
      async ({ values: _values, errors: _errors }) => {
        if (Object.values(_errors).some((value) => !!value)) {
          setErrors(_errors);
        } else {
          const { password: _password, ..._member } = _values;
          const response = await request<{ result: number; Token: string }>({
            method: 'POST',
            url: '/member',
            data: {
              member: _member,
              password: _password,
              password2: _password,
              ignoreDupe: true,
              clubid: 41971,
              origin: 'yes2chess.se',
            },
          });

          if (response) {
            setToken(response.data.Token);
          }
        }
      },
    );
  }

  return (
    <Container
      css={{ marginTop: 36 + 64, marginBottom: 36, maxWidth: 600 + 24 * 2 }}
    >
      <TextBubble>
        Vi hittade inte dig. Du måste skapa ett konto i vårt system för att
        komma vidare.
      </TextBubble>
      <form onSubmit={handleSubmit}>
        <Card css={{ position: 'relative' }}>
          <MemberFields
            {...{ fields, values, errors, onChange, onDateChange, onBlur }}
          />
          {isLoading && <Loading />}
        </Card>
        <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="primary"
          >
            Skapa
          </Button>
          {buttons}
        </ButtonRow>
      </form>
    </Container>
  );
};
