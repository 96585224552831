import { AxiosRequestConfig } from 'axios';
import { useContext, useState } from 'react';
import { memdbRequest, y2cRequest } from '@ssf/y2c-helpers';

import { UserContext } from '../contexts';

export interface NetworkError extends Error {
  request?: object;
  response?: {
    status: number;
    data: {
      error: {
        code: number;
        message: string;
      };
    };
  };
}

export interface Member {
  id: number;
  name: string;
  firstname: string;
  lastname: string;
  birthday: string;
  sex: number;
  street: string;
  zipcode: number;
  city: string;
  email: string;
  phone: string;
}

export interface Registration {
  member: Member;
  regstart: string;
  regend: string;
  date: string;
  membershiptype: number;
  fee: number;
}

export interface Role {
  orgrole: number;
  orgid: number;
  orgtype: number;
  memberid: number;
  start: string;
  seclevel: number;
  end: string;
  description: string;
}

export function useY2CRequest() {
  const [isLoading, setLoading] = useState(false);
  const { token } = useContext(UserContext);

  async function request<T>({ headers, ...options }: AxiosRequestConfig) {
    setLoading(true);

    try {
      const response = await y2cRequest<{ result: T }>({
        baseURL: process.env.Y2C_API_URL,
        ...options,
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...headers,
        },
      });
      setLoading(false);
      return response;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  return { request, isLoading };
}

export function useMemdbRequest() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<NetworkError>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { token } = useContext(UserContext);

  async function request<T>({ headers, ...options }: AxiosRequestConfig) {
    setLoading(true);
    setError(undefined);
    setErrorMessage(undefined);
    try {
      const response = await memdbRequest<T>({
        baseURL: `${process.env.MEMDB_URL}/api/v2`,
        ...options,
        headers: { ...(token && { Token: token }), ...headers },
      });
      setLoading(false);
      return response;
    } catch (e) {
      const localError: NetworkError = e;
      setLoading(false);
      setError(localError);
      setErrorMessage(
        localError.response &&
          localError.response.data &&
          localError.response.data.error
          ? localError.response.data.error.message
          : localError.message,
      );
    }
    return undefined;
  }

  return { request, error, errorMessage, isLoading };
}
