import { login } from '@ssf/memdb-api';
import { useState } from 'react';

import { useUser } from '.';

function calcErrorMessage(error: any) {
  if (error.response) {
    switch (error.response.data.error.code) {
      case 2:
        return {
          email: 'För många medlemmar har samma mailadress',
          password: '',
        };
      case 16:
        return { email: 'Ingen mailadress skickades', password: '' };
      case 17:
        return { email: '', password: 'Inget lösenord skickades' };
      case 0:
      case 18:
        return { email: 'Ingen medlem hittades', password: '' };
      case 19:
        return { email: '', password: 'Fel lösenord' };
      default:
        return { email: '', password: '' };
    }
  }
  return { email: 'Något gick fel', password: '' };
}

export const useLogin = () => {
  // const { request, error, isLoading } = useMemdbRequest();
  const { token, setToken } = useUser();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  const errorMessages: { email?: string; password?: string } = error
    ? calcErrorMessage(error)
    : {};

  async function doLogin(email: string, password: string) {
    setLoading(true);
    const response = await login({ email, password });
    setLoading(false);

    console.log(response, setError);

    if (response) {
      setToken(response.data.result);
    }
    return response;
  }

  return { token, setToken, doLogin, errorMessages, isLoading };
};
