import { Club, memdbRequest, Schema, UnSchema } from '.';

export interface School {
  id: number;
  name: string;
  city: string;

  clubs: Club[];
}

export function getSchool<
  T extends School,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(id: number, schema: S, token = '') {
  return memdbRequest.get<U>(`/school/${id}`, {
    params: { schema },
    headers: { token },
  });
}

export function getSchools<
  T extends School,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(params: { name: string; zipcode: number }, schema: S, token = '') {
  return memdbRequest.get<U[]>(`/school/name`, {
    params: { ...params, schema },
    headers: { token },
  });
}
