import {
  Box,
  Divider,
  ListItemText,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { debounce } from '@ssf/common';
import { getSchools, School as SchoolBase } from '@ssf/memdb-api';
import React, { useState } from 'react';

type School = Pick<SchoolBase, 'id' | 'name' | 'city'>;

const debouncer = debounce(
  (name: string) =>
    getSchools(
      { name: `%${name}%`, zipcode: 10000 },
      { id: 1, name: 1, city: 1 },
    ),
  400,
);

export const SchoolComboBox: React.FC<
  Partial<AutocompleteProps<School, false, false, false>> & {
    TextFieldProps?: TextFieldProps;
  }
> = ({ TextFieldProps, ...AutocompleteProps }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = React.useState<School[]>([]);
  const [input, setInput] = useState('');

  const handleInputChange = async (
    event: React.ChangeEvent<{}>,
    value: string,
  ) => {
    setInput(value);
    setOptions([]);
    if (value) {
      setLoading(true);
      const { data } = await debouncer(value);
      setLoading(false);
      setOptions(data);
    }
  };

  return (
    <Autocomplete
      open={!!input.length && open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      noOptionsText={
        <>
          <Typography>Vi hittade inte din skola</Typography>
          <Box my={1} mx={-8}>
            <Divider />
          </Box>
          <Typography variant="body2">
            Skolnamnen är hämtade från Skolverket, vilket innebär att det är
            skolans officiella namn som ska anges. Om det är en nystartad skola
            kan det hända att uppgiften saknas och då behöver ni kontakta oss.
          </Typography>
        </>
      }
      loading={loading}
      loadingText="Laddar..."
      getOptionLabel={(option: School) => option.name}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField {...params} label="Skolnamn" {...TextFieldProps} />
      )}
      renderOption={(option) => (
        <ListItemText secondary={option.city}>{option.name}</ListItemText>
      )}
      {...AutocompleteProps}
    />
  );
};
