import { ClassNames } from '@emotion/core';
import {
  Button,
  Card,
  CardContent,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { blue, green, grey, yellow } from '@material-ui/core/colors';
import { Alert, Check, Pencil } from 'mdi-material-ui';
import React, { DOMAttributes, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Club, getDocumentsStatus } from '@ssf/y2c-helpers';

import { ButtonRow, TextBubble } from '..';
import { Sign } from '../../../components';
import { useRegStatus } from '../../contexts';
import { useY2CRequest } from '../../hooks';

const DocStatus: React.FC<{ club: Club }> = ({ club }) => {
  const { status } = useRegStatus();
  const { documentsStatus } = getDocumentsStatus(
    status.documents,
    [club],
    [
      {
        id: -1,
        name: 'fake',
        groupid: -1,
        clubid: club.id,
        roles: [],
        players: [],
      },
    ],
  );

  return (
    <Tooltip
      key={club.id}
      placement="top"
      title={
        documentsStatus.status === 1
          ? 'Generera dokument för den här klubben'
          : documentsStatus.message ||
            'Vi har fått in alla dokument för den här klubben'
      }
    >
      {documentsStatus.status === 0 ? (
        <Alert fontSize="small" css={{ color: grey[500] }} />
      ) : documentsStatus.status === 1 ? (
        <Alert fontSize="small" css={{ color: yellow[800] }} />
      ) : documentsStatus.status === 2 ? (
        <Check fontSize="small" css={{ color: blue[800] }} />
      ) : (
        <Check fontSize="small" css={{ color: green[800] }} />
      )}
    </Tooltip>
  );
};

export function RegDocuments({ buttons }: { buttons: React.ReactNode }) {
  const { spacing } = useTheme<Theme>();
  const {
    status: { clubs = [], documents = {} },
    setStatus,
  } = useRegStatus();
  const printRef = useRef<HTMLDivElement>(null);
  const { request: y2cRequest } = useY2CRequest();

  const [club, setClub] = useState<Club | undefined>(clubs[0] || undefined);
  const document =
    club && club.startdate
      ? +club.startdate.substr(0, 4) < new Date().getFullYear()
        ? 'protokollArsmote'
        : 'protokollKonstituerandeMote'
      : undefined;

  const [editAble, setEditAble] = useState(false);

  const handleClubChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClub(clubs.find(({ id }) => id === +event.target.value));
    setEditAble(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current || null,
    pageStyle:
      'body { padding: 24px; } html, body { height: auto !important; } body > div > div { page-break-before: always; }',
  });

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = (event) => {
    event.preventDefault();

    if (club) {
      if (handlePrint) {
        handlePrint();
      }

      const prints = {
        ...documents,
        [club.id]: new Date().toLocaleDateString('sv-SE'),
      };
      setStatus({ documents: prints });

      y2cRequest({
        method: 'POST',
        url: '/store',
        data: {
          prints,
        },
      });
    }
  };

  const { documentsStatus } = getDocumentsStatus(
    documents,
    club ? [club] : [],
    [
      {
        id: -1,
        name: 'fake',
        groupid: -1,
        clubid: club ? club.id : -1,
        roles: [],
        players: [],
      },
    ],
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextBubble>
        Det här är en mall för de handlingar ni ska lämna in. Ni kan själva
        redigera så att allt blir korrekt.
      </TextBubble>

      <Card>
        <CardContent>
          <ClassNames>
            {({ css }) => (
              <TextField
                label="Välj klubb"
                value={club ? club.id : ''}
                onChange={handleClubChange}
                select
                fullWidth
                SelectProps={{
                  classes: {
                    root: css({
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  },
                }}
              >
                {clubs.map((_club) => (
                  <MenuItem key={_club.id} value={_club.id} dense>
                    <ListItemText css={{ margin: 0 }}>
                      {_club.name}
                    </ListItemText>
                    <DocStatus club={_club} />
                  </MenuItem>
                ))}
              </TextField>
            )}
          </ClassNames>
        </CardContent>
      </Card>

      <Card
        css={{
          marginTop: spacing(2),
          minHeight: 300,
          position: 'relative',
          overflow: 'visible',
        }}
      >
        {!editAble && (
          <Pencil
            onClick={() => setEditAble(true)}
            css={{
              cursor: 'pointer',
              position: 'absolute',
              zIndex: 10,
              top: spacing(10),
              right: -spacing(5),
              padding: spacing(1),
              backgroundColor: 'rgba(0,0,0,0.2)',
              color: '#000',
            }}
          />
        )}
        {documentsStatus.status === 3 && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: green[800],
              color: '#FFF',
              padding: spacing(2),
              borderRadius: '4px 4px 0 0',
            }}
          >
            <Check css={{ marginRight: spacing(0.5), marginTop: -2 }} />
            <Typography>Den här klubben är godkänd</Typography>
          </div>
        )}
        <CardContent>
          {club && document && (
            <div
              ref={printRef}
              suppressContentEditableWarning
              contentEditable={editAble}
            >
              <Sign document={document} clubId={club.id} />
              {document === 'protokollArsmote' && club.active === 1 && (
                <Sign document="verksamhetsberattelse" clubId={club.id} />
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <ButtonRow>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          css={{ margin: `${spacing(1)}px 0` }}
        >
          Skriv ut
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  );
}
