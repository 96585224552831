import { Box as MuiBox } from '@material-ui/core';
import React from 'react';

import { Content } from '..';

export type Box = {
  type: 'box';
  props: { property: string; value: string }[];
  content: Content;
};

export const Box: React.FC<{ data: Box }> = ({ data: { props, content } }) => (
  <MuiBox
    {...Object.fromEntries(
      props.map(({ property, value }) => [property, JSON.parse(value)]),
    )}
  >
    <Content data={content} />
  </MuiBox>
);
