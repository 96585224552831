import {
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { getTeamsStatus, Group, Team, Tournament } from '@ssf/y2c-helpers';
import { Alert, Check, Close } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import {
  BackButton,
  ButtonRow,
  ConfirmDialog,
  Loading,
  RegCreateUpdateTeam,
} from '..';
import { TournamentsContext, useRegStatus } from '../../contexts';
import { useMemdbRequest } from '../../hooks';

export const getGroups = (tournaments?: Tournament[]) =>
  (tournaments || []).flatMap(({ classes }) =>
    classes.flatMap(({ groups: g }) => g.flatMap((group) => group)),
  );

function prependTeamName(_team: Team, groups: { [key: number]: Group }) {
  const group = groups[_team.groupid] || {};
  const str =
    ((group.name || '').startsWith('Yes2Chess') &&
      (group.name || '').split(' ')[1]) ||
    '';
  return str ? <span css={{ fontWeight: 300 }}>{str}: </span> : '';
}

const TeamStatus: React.FC<{ team: Team }> = ({ team }) => {
  const {
    status: { clubs = [] },
  } = useRegStatus();
  const {
    teamsStatus: { status, message },
  } = getTeamsStatus([team], clubs);

  return (
    <Tooltip
      placement="top"
      title={message || 'Den här klubben är godkänd för deltagande i Yes2Chess'}
    >
      {status !== 3 ? (
        <Alert fontSize="small" css={{ color: yellow[800] }} />
      ) : (
        <Check fontSize="small" css={{ color: green[800] }} />
      )}
    </Tooltip>
  );
};

export function RegTeams({ buttons }: { buttons: React.ReactNode }) {
  const { tournaments } = useContext(TournamentsContext);
  const {
    status: { teams = [] },
    setStatus,
    page,
    setPage,
  } = useRegStatus();

  const groups = getGroups(tournaments).reduce<{ [key: number]: Group }>(
    (obj, group) => ({ ...obj, [group.id]: group }),
    {},
  );
  const { spacing } = useTheme<Theme>();
  const [team, setTeam] = useState<Team>();
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const { request, isLoading } = useMemdbRequest();

  const handleTeamSubmit = (_team: Team) => {
    if (team) {
      teams.splice(teams.indexOf(team), 1, _team);
    } else {
      teams.push(_team);
    }
    setStatus({ teams: [...teams] });
    setPage(['teams']);
  };

  const handleRemoveTeam = async () => {
    if (team) {
      const response = await request<{ result: number }>({
        method: 'DELETE',
        url: `tournament/group/${team.groupid}/contender/${team.id}/teamnumber/1`,
      });
      if (response && response.data) {
        teams.splice(teams.indexOf(team), 1);
        setStatus({ teams: [...teams] });
      }
      setConfirmRemoval(false);
    }
  };

  return !page[1] ? (
    <>
      <Card css={{ position: 'relative' }}>
        <List
          css={{ maxHeight: 314, overflow: 'auto', backgroundColor: 'inherit' }}
          disablePadding
        >
          <ListSubheader css={{ backgroundColor: 'inherit' }}>
            Anmälda klubbar
          </ListSubheader>
          {teams.map((_team) => (
            <ListItem
              key={_team.id}
              button
              dense
              onClick={() => {
                setTeam(_team);
                setPage(['teams', 'team']);
              }}
              selected={false}
            >
              <ListItemText>
                {prependTeamName(_team, groups)}
                {_team.name}
              </ListItemText>
              <TeamStatus team={_team} />
              <ListItemSecondaryAction>
                <Tooltip
                  placement="top"
                  title={`Ta bort anmälan för ${_team.name}`}
                  aria-label="add"
                >
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setTeam(_team);
                        setConfirmRemoval(true);
                      }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button
          variant="contained"
          color="primary"
          css={{ margin: `${spacing(1)}px 0` }}
          onClick={() => {
            setTeam(undefined);
            setPage(['teams', 'team']);
          }}
        >
          Ny anmälan
        </Button>
        {buttons}
      </ButtonRow>

      <ConfirmDialog
        open={confirmRemoval}
        button={
          <Button autoFocus color="primary" onClick={handleRemoveTeam}>
            Ta bort
          </Button>
        }
        onClose={() => setConfirmRemoval(false)}
      >
        <Typography>
          Är du säker på att du vill ta bort anmälan för {team ? team.name : ''}
          ?
        </Typography>
      </ConfirmDialog>
    </>
  ) : (
    <RegCreateUpdateTeam
      team={team}
      onSubmit={handleTeamSubmit}
      buttons={
        <BackButton
          onBack={() =>
            teams.length ? setPage(['teams']) : setPage(['overview'])
          }
        />
      }
    />
  );
}
