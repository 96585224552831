import React from 'react';

export type Image = {
  type: 'image';
  image: string;
  hero: boolean;
  circular: boolean;
};

export const Image: React.FC<{ data: Image }> = ({ data }) => (
  <img
    src={data.image}
    css={[
      data.hero
        ? {
            minWidth: 220,
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
          }
        : { width: '100%' },
      data.circular && {
        borderRadius: '50%',
      },
    ]}
  />
);
