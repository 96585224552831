import { Grid, GridProps } from '@material-ui/core';
import React from 'react';

import { Box, Button, Card, Image, PromoArea, Text } from '.';

export type Content = {
  type: 'content';
  props: Pick<GridProps, 'direction' | 'spacing' | 'alignItems' | 'wrap'>;
  blocks: ({ gridProps?: { property: string; value: string }[] } & (
    | Content
    | Card
    | Text
    | Image
    | Button
    | Box
    | PromoArea
  ))[];
};

export const Content: React.FC<{ data: Content }> = ({ data }) => (
  <Grid container {...data.props}>
    {data.blocks.map((block, index) => (
      <Grid
        key={block.type + index}
        item
        {...(block.gridProps &&
          Object.fromEntries(
            block.gridProps.map(({ property, value }) => [
              property,
              JSON.parse(value),
            ]),
          ))}
      >
        {block.type === 'content' ? (
          <Content data={block} />
        ) : block.type === 'card' ? (
          <Card data={block} />
        ) : block.type === 'box' ? (
          <Box data={block} />
        ) : block.type === 'text' ? (
          <Text data={block} />
        ) : block.type === 'image' ? (
          <Image data={block} />
        ) : block.type === 'button' ? (
          <Button data={block} />
        ) : block.type === 'promoArea' ? (
          <PromoArea data={block} />
        ) : null}
      </Grid>
    ))}
  </Grid>
);
