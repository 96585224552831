import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { ChevronDown } from 'mdi-material-ui';
import React from 'react';

import { Markdown } from '.';

export const Faq: React.FC<{ tag: string }> = ({ tag }) => {
  const {
    allFile: { edges },
  } = useStaticQuery<{
    allFile: {
      edges: {
        node: {
          childMarkdownRemark: {
            rawMarkdownBody: string;
            frontmatter: {
              tags: string[];
              title: string;
              expanded: boolean;
            };
          };
        };
      }[];
    };
  }>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "faq" } }) {
          edges {
            node {
              childMarkdownRemark {
                rawMarkdownBody
                frontmatter {
                  tags
                  title
                  expanded
                }
              }
            }
          }
        }
      }
    `,
  );

  const questions = edges
    .map(({ node }) => node.childMarkdownRemark)
    .filter(({ frontmatter }) => frontmatter.tags.includes(tag));

  return (
    <>
      {questions.map(
        ({ frontmatter: { title, expanded }, rawMarkdownBody }) => (
          <Accordion
            key={title}
            defaultExpanded={expanded}
            css={{
              backgroundColor: 'transparent',
              border: '1px solid rgba(0,0,0,.12)',
              borderTopWidth: 0,
              boxShadow: 'none',
              '&:first-of-type, &.Mui-expanded, &.Mui-expanded + &': {
                borderTopWidth: '1px',
              },
            }}
          >
            <AccordionSummary expandIcon={<ChevronDown />}>
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Markdown markdown={rawMarkdownBody} variant="body2" />
            </AccordionDetails>
          </Accordion>
        ),
      )}
    </>
  );
};
