import { Container as MUIContainer, ContainerProps } from '@material-ui/core';
import React from 'react';

export const Container: React.FC<ContainerProps> = (props) => (
  <MUIContainer
    css={{
      marginTop: 36 + 64,
      marginBottom: 36,
      maxWidth: 960 + 24 * 2,

      flex: '1 1 auto',
    }}
    {...props}
  />
);
