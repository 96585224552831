import {
  ApiStatus,
  defaultStatus,
  getRegStatus,
  playingYear,
  RegStatus,
  statusRequest,
} from '@ssf/y2c-helpers';
import React, { useContext, useEffect, useState } from 'react';

import { useY2CRequest } from '../hooks';
import { TournamentsContext, UserContext } from '.';

export interface IRegContext {
  isLoading: boolean;
  status: RegStatus;
  setStatus(status: Partial<RegStatus>, page?: IRegContext['page']): void;
  page:
    | ['overview']
    | ['account']
    | ['school']
    | ['clubs']
    | ['clubs', 'club']
    | ['clubs', 'member']
    | ['teams']
    | ['teams', 'team']
    | ['documents']
    | ['verifications'];
  setPage(page: IRegContext['page']): void;
}

export const RegContext = React.createContext<IRegContext>({
  isLoading: false,
  status: defaultStatus,
  setStatus: () => {},
  page: ['overview'],
  setPage: () => {},
});

export const RegContextProvider: React.FC = (props) => {
  const { user, token } = useContext(UserContext);
  const { tournaments } = useContext(TournamentsContext);
  const { request: y2cRequest } = useY2CRequest();
  const [isLoading, setLoading] = useState(false); // can't use useMemdbRequest? request array...
  const [status, setStatus] = useState<RegStatus>(defaultStatus);
  const [page, setPage] = useState<IRegContext['page']>(['overview']);

  function setRegStatus(regStatus: Partial<RegStatus>) {
    const newStatus = getRegStatus({ ...status, ...regStatus });

    setStatus(newStatus);

    const memberId = new URLSearchParams(location.search).get('memberId');

    const data: Omit<ApiStatus, 'user' | 'created' | 'updated' | 'comment'> = {
      tournament: `y2c-${playingYear}`,
      ...(memberId && { user: Number(memberId) }),
      ...(!memberId && user && { name: user.name }),
      ...(newStatus.school && {
        school: newStatus.school.name,
        districtid: newStatus.school.districtid,
      }),
      nClubs: newStatus.clubs ? newStatus.clubs.length : 0,
      nTeams: newStatus.teams ? newStatus.teams.length : 0,
      status: {
        school: newStatus.schoolStatus.status,
        clubs: newStatus.clubsStatus.status,
        teams: newStatus.teamsStatus.status,
        documents: newStatus.documentsStatus.status,
        verifications: newStatus.verificationsStatus.status,
      },
    };
    y2cRequest({
      method: 'POST',
      url: '/status',
      data,
    });
  }

  useEffect(() => {
    const memdbUrl = process.env.MEMDB_URL;
    const y2cApiUrl = process.env.Y2C_API_URL;
    if (tournaments && memdbUrl && y2cApiUrl && token) {
      setLoading(true);
      statusRequest({
        memdbUrl,
        y2cApiUrl,
        token,
        tournaments,
        memberId: new URLSearchParams(location.search).get('memberId'),
      }).then((statuses) => {
        setRegStatus(Object.assign({}, ...statuses));
        setLoading(false);
      });
    }
  }, []);

  return (
    <RegContext.Provider
      value={{
        isLoading,
        status,
        setStatus: (newStatus, newPage) => {
          if (Object.keys(newStatus).length) {
            setRegStatus(newStatus);
          }
          if (newPage) {
            setPage(newPage);
          }
        },
        page,
        setPage,
      }}
      {...props}
    />
  );
};

export const useRegStatus = () => useContext(RegContext);
