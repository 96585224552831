// import { Button, Typography } from '@material-ui/core';
// import { Link } from 'gatsby';
import { Box } from '@material-ui/core';
import React from 'react';

import { Markdown } from '..';

export type Text = {
  type: 'text';
  text: string;
};

export const Text: React.FC<{ data: Text }> = ({ data }) => (
  <Box m={2} position="relative" zIndex="10">
    <Markdown markdown={data.text} />
  </Box>
);
