import emotionStyled, { CreateStyled } from '@emotion/styled';
import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme({
  typography: { fontFamily: '"Lato", sans-serif' },
});
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#C0175D',
    },
    // secondary: {
    //   main: '#8FBF21',
    // },
  },

  typography: {
    fontFamily: '"Lato", sans-serif',
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
      ...defaultTheme.typography.h2,
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      ...defaultTheme.typography.h6,
      fontWeight: 700,
    },
    h3: {
      ...defaultTheme.typography.body1,
      fontWeight: 700,
    },
    // h2: {
    //   ...defaultTheme.typography.h6,
    // },
  },

  overrides: {
    MuiListSubheader: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
});

export const styled = emotionStyled as CreateStyled<typeof theme>;
