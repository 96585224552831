import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import svSE from '../locales/sv-SE.json';

i18next.use(initReactI18next).init({
  fallbackLng: 'sv-SE',
  defaultNS: 'common',
  resources: {
    'sv-SE': svSE,
  },
  debug: process.env.NODE_ENV === 'development',
  react: {
    wait: true,
  },
});

export default i18next;
