import React from 'react';

import { useUser } from '../contexts';

export const Match = ({ matchId }: { matchId: number }) => {
  const { token } = useUser();

  return (
    matchId && (
      <iframe
        src={`https://superold.yes2chess.se/match/${matchId}?token=${
          token || ''
        }&memdbUrl=${encodeURIComponent(process.env.MEMDB_URL || '')}`}
        css={{
          width: '100%',
          height: 'calc(100vh - 64px)',
          border: 0,
        }}
      />
    )
  );
};
