import { Button as MuiButton } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';

export type Button = {
  type: 'button';
  button: {
    title: string;
    link: string;
  };
};

export const Button: React.FC<{ data: Button }> = ({
  data: {
    button: { title, link },
  },
}) =>
  (link || '').startsWith('http') ? (
    <a key={link} href={link} css={{ textDecoration: 'none' }}>
      <MuiButton variant="contained" color="primary">
        {title}
      </MuiButton>
    </a>
  ) : (
    <Link key={link} to={link} css={{ textDecoration: 'none' }}>
      <MuiButton variant="contained" color="primary">
        {title}
      </MuiButton>
    </Link>
  );
