import { Club, Member } from '.';

export interface Role {
  memberid: number;
  clubid: number;
  orgtype: number;
  orgrole: number;
  start: string;
  end: string;
  phone: string;
  email: string;

  club: Club;
  member: Member;
}

export const Role = {
  PRESIDENT: 100,
  SECRETARY: 101,
  BOARDMEMBER: 102,
  CASHIER: 103,
  YOUTHORGANIZER: 104,
  MEMBERLISTKEEPER: 105,
  RATINGLISTLISTKEEPER: 106,
  CONTACTPERSON: 107,
  ACCOUNTANT: 108,
  ELECTION_COMMITTEE: 109,
};
