import { getClub, getMember } from '@ssf/memdb-api';
import matter from 'gray-matter';
import React, { useEffect, useState } from 'react';

import medlemslapp from '../../documents/medlemslapp.md';
import protokollArsmote from '../../documents/protokoll-arsmote.md';
import protokollKonstituerandeMote from '../../documents/protokoll-konstituerande-mote.md';
import verksamhetsberattelse from '../../documents/verksamhetsberattelse.md';
import { Markdown } from '.';

const baseDocs = {
  medlemslapp,
  protokollArsmote,
  protokollKonstituerandeMote,
  verksamhetsberattelse,
};
const docs = Object.fromEntries(
  Object.entries(baseDocs).map(([key, value]) => [key, matter(value)]),
);

async function getMembersData(memberIds: number[], clubId: number) {
  const [{ data: club }, membersResponse] = await Promise.all([
    getClub(clubId, {
      name: 1,
      season: {
        startDate: 1,
        endDate: 1,
      },
    }),
    Promise.all(
      memberIds.map((memberId) =>
        getMember(memberId, {
          name: 1,
          birthday: 1,
          sex: 1,
          street: 1,
          zipcode: 1,
          city: 1,
        }),
      ),
    ),
  ]);

  return membersResponse.map(({ data }) => ({
    club,
    member: { ...data, sex: data.sex === 1 ? 'kvinna' : 'man' },
  }));
}

async function getClubData(clubId: number) {
  const { data: club } = await getClub(clubId, {
    name: 1,
    city: 1,
    roles: [
      { member: { name: 1 }, orgrole: 1, end: 1, filter: '(end=1970-01-01)' },
    ],
  });

  let president = { name: '………………………………' };
  let secretary = { name: '………………………………' };
  let boardmember = { name: '………………………………' };
  let accountant = { name: '………………………………' };

  club.roles.forEach((role) => {
    if (role.orgrole === 100) {
      president = role.member;
    } else if (role.orgrole === 101) {
      secretary = role.member;
    } else if (role.orgrole === 102) {
      boardmember = role.member;
    } else if (role.orgrole === 108) {
      accountant = role.member;
    }
  });

  return [
    {
      club: {
        ...club,
        president,
        secretary,
        boardmember,
        accountant,
      },
      date: new Date().toLocaleDateString('sv-SE'),
    },
  ];
}

async function getVBData(clubId: number) {
  const lastYear = new Date().getFullYear() - 1;
  const { data: club } = await getClub(clubId, {
    name: 1,
    school: {
      name: 1,
    },
    registrations: [
      {
        member: {
          birthday: 1,
        },
        membershiptype: 1,
        date: 1,
        filter: `(&(date>=${lastYear}-01-01)(date<=${lastYear}-12-31)(membershiptype<=119))`,
      },
    ],
    roles: [
      {
        orgrole: 1,
        end: 1,
        member: {
          name: 1,
        },
        filter: '(&(orgrole=100)(end=1970-01-01))',
      },
    ],
  });

  let president = { name: '………………………………' };
  let secretary = { name: '………………………………' };
  let boardmember = { name: '………………………………' };
  let accountant = { name: '………………………………' };

  club.roles.forEach((role) => {
    if (role.orgrole === 100) {
      president = role.member;
    } else if (role.orgrole === 101) {
      secretary = role.member;
    } else if (role.orgrole === 102) {
      boardmember = role.member;
    } else if (role.orgrole === 108) {
      accountant = role.member;
    }
  });

  const year6 = new Date().getFullYear() - 6;
  const year25 = new Date().getFullYear() - 25;
  const nYouth = club.registrations.reduce<number>(
    (n, { member: { birthday } }) =>
      n +
      (+birthday.substr(0, 4) <= year6 && +birthday.substr(0, 4) >= year25
        ? 1
        : 0),
    0,
  );

  return [
    {
      club: {
        ...club,
        president,
        secretary,
        boardmember,
        accountant,
      },
      nYouth,
      nRegistrations: club.registrations.length,
      lastYear,
      date: new Date().toLocaleDateString('sv-SE'),
    },
  ];
}

export const Sign: React.FC<
  { onResponse?: () => void } & (
    | {
        document: 'medlemslapp';
        memberIds: number[];
        clubId: number;
      }
    | {
        document:
          | 'protokollArsmote'
          | 'protokollKonstituerandeMote'
          | 'verksamhetsberattelse';
        clubId: number;
        memberIds: undefined;
      }
  )
> = ({ onResponse, ...props }) => {
  const { content } = docs[props.document];
  const [values, setValues] = useState<object[]>([]);

  useEffect(() => {
    (async () => {
      setValues([]);
      if (props.document === 'medlemslapp') {
        setValues(await getMembersData(props.memberIds, props.clubId));
      } else if (props.document === 'verksamhetsberattelse') {
        setValues(await getVBData(props.clubId));
      } else {
        setValues(await getClubData(props.clubId));
      }
      if (onResponse) {
        onResponse();
      }
    })();
  }, [props.document, props.clubId, props.memberIds]);

  try {
    return content && values.length ? (
      <>
        {values.map((v, index) => (
          <Markdown key={index.toString()} markdown={content} values={v} />
        ))}
      </>
    ) : null;
  } catch (e) {
    return null;
  }
};
