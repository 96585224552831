import axios, { AxiosRequestConfig } from 'axios'

const isObject = (data: unknown): data is { [key: string]: unknown } => data && typeof data === 'object'

const serialize = (data: string | number | boolean | object) =>
  isObject(data)
    ? Object.keys(data)
      .map(
        key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            isObject(data[key]) ? JSON.stringify(data[key]) : (data[key] as string | number | boolean)
          )}`
      )
      .join('&')
    : data.toString()

export function y2cRequest<R>({ headers, data, ...options }: AxiosRequestConfig) {
  return axios.request<R>({
    ...options,
    ...(data && { data: JSON.stringify(data) }),
    headers: {
      ...(options.method && options.method.toUpperCase() === 'POST' && { 'Content-Type': 'application/json; charset=UTF-8' }),
      ...headers
    }
  })
}

export function memdbRequest<R>({ headers, data, ...options }: AxiosRequestConfig) {
  return axios.request<R>({
    paramsSerializer: serialize,
    ...options,
    ...(data && { data: serialize(data) }),
    headers: {
      ...(options.method &&
        options.method.toUpperCase() === 'POST' && { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
      ...headers
    }
  })
}
