import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Password } from '@ssf/components';
import * as React from 'react';

import { useLogin } from '../hooks';
import { Loading, RecoverPassword } from '.';

export const LoginDialog: React.FC<
  React.ComponentProps<typeof Dialog> & { onClose(): void }
> = (props) => {
  const { doLogin, isLoading, errorMessages } = useLogin();

  async function handleSubmit(
    event: React.FormEvent<
      HTMLFormElement & { email: HTMLInputElement; password: HTMLInputElement }
    >,
  ) {
    event.preventDefault();

    const { email, password } = event.currentTarget;

    const response = await doLogin(email.value, password.value);

    if (response) {
      props.onClose();
    }
  }

  return (
    <Dialog {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Logga in</DialogTitle>
        <DialogContent>
          <TextField
            name="email"
            autoFocus
            margin="dense"
            label="Email eller medlemsid"
            type="text"
            fullWidth
            error={!!errorMessages.email}
            helperText={errorMessages.email}
          />
          <Password
            label="Lösenord"
            error={!!errorMessages.password}
            helperText={errorMessages.password}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          css={{
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
          }}
        >
          <Button type="submit" color="primary">
            Logga in
          </Button>
          <RecoverPassword />
        </DialogActions>
      </form>
      {isLoading && <Loading />}
    </Dialog>
  );
};
