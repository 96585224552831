import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme({
  typography: { fontFamily: '"Lato", sans-serif' },
});

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#014898',
    },
  },

  typography: {
    ...defaultTheme.typography,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    h1: {
      ...defaultTheme.typography.h2,
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      ...defaultTheme.typography.h5,
      fontWeight: 700,
    },
    h3: {
      ...defaultTheme.typography.h6,
      fontWeight: 700,
    },
    h4: {
      ...defaultTheme.typography.body1,
      fontWeight: 700,
    },
    // h2: {
    //   ...defaultTheme.typography.h6,
    // },
  },

  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },

  overrides: {
    MuiListSubheader: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },

    MuiFormControlLabel: {
      root: {
        height: 32,
      },
    },
  },
});

export const s4Theme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: '#82c440',
      contrastText: '#FFF',
    },
  },
});
