import { Paper } from '@material-ui/core';
import React from 'react';

import { Content } from '..';

export type Card = {
  type: 'card';
  style?: { property: string; value: string }[];
} & Omit<Content, 'type'>;

export const Card: React.FC<{ data: Card }> = ({
  data: { style, ...data },
}) => (
  <Paper
    css={
      style &&
      Object.fromEntries(style.map(({ property, value }) => [property, value]))
    }
  >
    <Content data={{ ...data, type: 'content' }} />
  </Paper>
);
