import { Container } from '@material-ui/core';
import React from 'react';

import { LoginCard, LoginCardProps, TextBubble } from '..';
import regSettings from '../../../../settings/registration.json';

export const RegLogin: React.FC<LoginCardProps> = (props) => (
  <Container
    css={{ marginTop: 36 + 64, marginBottom: 36, maxWidth: 600 + 24 * 2 }}
  >
    <TextBubble>
      {regSettings.createAccounts ? (
        <>
          Ja, vi hittade dig. Fyll i ditt lösenord. Om du har glömt bort det kan
          du klicka på knappen <i>"GLÖMT LÖSENORD"</i>
        </>
      ) : (
        <>
          Fyll i dina inloggningsuppgifter. Om du har glömt bort det kan du
          klicka på knappen <i>"GLÖMT LÖSENORD"</i>
        </>
      )}
    </TextBubble>
    <LoginCard {...props} />
  </Container>
);
