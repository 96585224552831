import { Member, memdbRequest, Role, Schema, School, UnSchema } from '.';

export interface Registration {
  memberid: number;
  clubid: number;

  member: Member;
}

export interface Club {
  id: number;
  name: string;
  season: {
    startDate: string;
    endDate: string;
  };
  active: boolean;

  registrations: Registration[];
  roles: Role[];
  school: School;
}

export function getClub<
  T extends Club,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(id: number, schema: S, token = '') {
  return memdbRequest.get<U>(`/club/${id}`, {
    params: { schema },
    headers: { token },
  });
}
