import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { decode } from 'jsonwebtoken';
import React, { useEffect } from 'react';

import { useLogin, useMemdbRequest } from '../hooks';
import { Loading, Password } from '.';

export function ResetPassword() {
  const [resetPasswordToken, setResetPasswordToken] = React.useState<string>();
  const [isOpen, setOpen] = React.useState(false);
  const { request, isLoading, errorMessage: error } = useMemdbRequest();
  const { doLogin } = useLogin();

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('reset-password');
    if (token) {
      setResetPasswordToken(token);
      setOpen(true);
      url.searchParams.delete('reset-password');
      window.history.replaceState({}, document.title, url.toString());
    }
  }, []);

  function handleToggleOpen() {
    setOpen(!isOpen);
  }

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement & { password: HTMLInputElement }>,
  ) {
    event.preventDefault();
    event.stopPropagation();

    if (resetPasswordToken) {
      const { password } = event.currentTarget;
      const decodedToken = decode(resetPasswordToken);

      if (
        decodedToken &&
        typeof decodedToken === 'object' &&
        decodedToken.jti
      ) {
        request({
          method: 'POST',
          url: `/member/${decodedToken.jti}`,
          headers: {
            Token: resetPasswordToken,
          },
          data: {
            password: password.value,
            password2: password.value,
          },
        }).then(() => {
          doLogin(decodedToken.sub, password.value).then(() => {
            handleToggleOpen();
          });
        });
      }
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleToggleOpen}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Återställ lösenord</DialogTitle>
        <DialogContent>
          <Typography>
            Fyll i ditt nya lösenord nedan och klicka sedan på skicka
          </Typography>
          <Password
            label="Nytt lösenord"
            autoFocus
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Skicka
          </Button>
        </DialogActions>
      </form>
      {isLoading && <Loading />}
    </Dialog>
  );
}
