import {
  Button,
  Card,
  CardContent,
  Checkbox,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import { TableCellProps } from '@material-ui/core/TableCell';
import { Role, Team } from '@ssf/y2c-helpers';
import { Alert } from 'mdi-material-ui';
import React, { DOMAttributes, useContext, useEffect, useState } from 'react';

import { ButtonRow, getGroups, Loading } from '..';
import registration from '../../../../settings/registration.json';
import { TournamentsContext, UserContext, useRegStatus } from '../../contexts';
import { Member, useMemdbRequest } from '../../hooks';

type Person = Pick<
  Member,
  'id' | 'name' | 'birthday' | 'sex' | 'email' | 'phone'
> & {
  isPlayer: boolean;
  isTeamLeader: boolean;
  teamLeaderStart?: string;
};

const PersonCell: React.FC<TableCellProps> = (props) => (
  <TableCell css={{ paddingTop: 0, paddingBottom: 0 }} {...props} />
);

export const RegCreateUpdateTeam: React.FC<{
  buttons: React.ReactNode;
  team?: Team;
  onSubmit(team: Team): void;
}> = ({ buttons, team: teamProp, onSubmit }) => {
  const { spacing } = useTheme<Theme>();
  const { tournaments } = useContext(TournamentsContext);
  const { user } = useContext(UserContext);
  const {
    status: { clubs = [], teams = [] },
  } = useRegStatus();
  const { request } = useMemdbRequest();
  const [isLoading, setLoading] = useState(false);
  const groups = getGroups(tournaments);
  const [team, setTeam] = useState<
    Pick<Team, 'roles' | 'players'> & Partial<Omit<Team, 'roles' | 'players'>>
  >(
    teamProp || {
      roles: [],
      players: [],
    },
  );
  const [persons, setPersons] = useState<Person[]>([]);

  const oneTeamPerClub = true;
  // const {
  //   tournament: {
  //     acf: { one_team_per_club: oneTeamPerClub }
  //   }
  // } = useWp()

  const roles = team.roles.reduce<{
    [key: number]: Pick<Person, 'isTeamLeader' | 'teamLeaderStart'>;
  }>(
    (obj, role) => ({
      ...obj,
      [role.memberid]: { isTeamLeader: true, teamLeaderStart: role.start },
    }),
    {
      [user ? user.id : 0]: {
        isTeamLeader: true,
        teamLeaderStart: new Date().toLocaleDateString('sv-SE'),
      },
    },
  );
  const players = team.players.reduce<{
    [key: number]: Pick<Person, 'isPlayer'>;
  }>((obj, player) => ({ ...obj, [player.member.id]: { isPlayer: true } }), {});

  const playersInOtherTeams = teams.reduce<{ [key: number]: boolean }>(
    (teamsObj, { players: _players }) => ({
      ...teamsObj,
      ..._players.reduce(
        (playersObj, { member: { id } }) => ({
          ...playersObj,
          [id]: !players[id],
        }),
        {},
      ),
    }),
    {},
  );

  useEffect(() => {
    if (team.clubid) {
      setLoading(true);
      request<{ registrations: { member: Member }[] }>({
        url: `/club/${team.clubid}`,
        params: {
          schema: {
            registrations: [
              {
                member: {
                  id: 1,
                  name: 1,
                  birthday: 1,
                  sex: 1,
                  email: 1,
                  phone: 1,
                },
                regend: 1,
                filter: `(regend>=${registration.regYear})`,
              },
            ],
          },
        },
      }).then((response) => {
        if (response && response.data) {
          setPersons(
            response.data.registrations.map(({ member }) => ({
              ...member,
              isPlayer: false,
              isTeamLeader: false,
              ...roles[member.id],
              ...players[member.id],
            })),
          );
        }
        setLoading(false);
      });
    }
  }, [team.clubid]);

  const handleClubChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clubid = +event.target.value;
    const club = clubs.find((_club) => _club.id === clubid);
    setTeam({ ...team, clubid, name: club ? club.name : '' });
  };

  const handleTournamentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTeam({ ...team, groupid: +event.target.value });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTeam({ ...team, name: event.currentTarget.value });
  };

  const handleTeamLeaderChange = (person: Person) => () => {
    // eslint-disable-next-line no-param-reassign
    person.isTeamLeader = !person.isTeamLeader;
    setPersons([...persons]);
  };

  const handlePlayerChange = (person: Person) => () => {
    // eslint-disable-next-line no-param-reassign
    person.isPlayer = !person.isPlayer;
    setPersons([...persons]);
  };

  const teamLeaderRequest = ({
    teamId,
    memberid,
    isTeamLeader,
    teamLeaderStart = new Date().toLocaleDateString('sv-SE'),
  }: {
    teamId: number;
    memberid: number;
    isTeamLeader: boolean;
    teamLeaderStart?: string;
  }) => {
    const defaultRole: Omit<Role, 'club'> = {
      orgtype: 4,
      orgid: teamId,
      orgrole: 201,
      memberid: -1,
      start: new Date().toLocaleDateString('sv-SE'),
      end: '1970-01-01',
      seclevel: -1,
      description: '',
    };

    return request<{ result: number }>({
      method: 'POST',
      url: `member/${memberid}/role`,
      data: {
        role: {
          ...defaultRole,
          memberid,

          ...(isTeamLeader
            ? false
            : {
                start: teamLeaderStart,
                end: new Date().toLocaleDateString('sv-SE'),
              }),
        },
      },
    });
  };

  const handleSubmitAsync = async () => {
    setLoading(true);

    if (!teamProp) {
      const club = clubs.find(({ id }) => id === team.clubid);
      if (club) {
        team.name = team.name || club.name;

        const memberId = Number(
          new URLSearchParams(location.search).get('memberId'),
        );

        const teamResponse = await request<{ result: number }>({
          method: 'POST',
          url: 'tournament/team',
          data: {
            team: {
              name: team.name,
              schoolid: club.schoolid,
              clubid: team.clubid,
              teamnumber: 1,
            },
            captain: !memberId,
          },
        });

        if (teamResponse && teamResponse.data) {
          team.id = teamResponse.data.result;

          if (memberId) {
            await teamLeaderRequest({
              teamId: team.id,
              memberid: memberId,
              isTeamLeader: true,
            });
            await request({
              method: 'POST',
              url: `tournament/group/${team.groupid}/contender/${team.id}/teamnumber/1`,
              data: {
                contender: {
                  partid: team.id,
                  groupid: team.groupid,
                  type: 4,
                  teamnumber: 1,
                  regdate: new Date().toLocaleDateString('sv-SE'),
                },
              },
            });
          }

          await request({
            method: 'POST',
            url: `tournament/group/${team.groupid}/contender/${team.id}/teamnumber/1`,
            data: {
              contender: {
                partid: team.id,
                groupid: team.groupid,
                type: 4,
                teamnumber: 1,
                regdate: new Date().toLocaleDateString('sv-SE'),
              },
            },
          });
        }
      }
    }

    await Promise.all(
      persons.reduce<Promise<unknown>[]>(
        (requests, { id, isTeamLeader, isPlayer, teamLeaderStart }) => {
          if ((roles[id] ? roles[id].isTeamLeader : false) !== isTeamLeader) {
            requests.push(
              teamLeaderRequest({
                teamId: team.id || -1,
                memberid: id,
                isTeamLeader,
                teamLeaderStart,
              }),
            );
          }
          if (team.id) {
            if (isPlayer && (!players[id] || !players[id].isPlayer)) {
              requests.push(
                request({
                  method: 'POST',
                  url: `tournament/team/${team.id}/player`,
                  data: {
                    reg: {
                      orgid: team.id,
                      memberid: id,
                      tournamentid: -1, // todo
                      notnationalcitizen: 0,
                      regdate: new Date().toLocaleDateString('sv-SE'),
                    },
                  },
                }),
              );
            } else if (!isPlayer && players[id] && players[id].isPlayer) {
              requests.push(
                request({
                  method: 'DELETE',
                  url: `tournament/team/${team.id}/player/${id}`,
                }),
              );
            }
          }

          return requests;
        },
        [],
      ),
    );

    setLoading(false);

    if (team.id && team.name && team.clubid && team.groupid) {
      onSubmit({
        id: team.id,
        name: team.name,
        clubid: team.clubid,
        groupid: team.groupid,
        roles: persons.reduce<Team['roles']>(
          (_roles, { id, isTeamLeader, teamLeaderStart }) => [
            ..._roles,
            ...(isTeamLeader
              ? [
                  {
                    memberid: id,
                    start:
                      teamLeaderStart || new Date().toLocaleDateString('sv-SE'),
                  },
                ]
              : []),
          ],
          [],
        ),
        players: persons.reduce<Team['players']>(
          (_players, { id, sex, birthday, email, phone, isPlayer }) => [
            ..._players,
            ...(isPlayer
              ? [{ member: { id, sex, birthday, email, phone } }]
              : []),
          ],
          [],
        ),
      });
    }
  };

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = (event) => {
    event.preventDefault();
    handleSubmitAsync();
  };

  const isStudent = (date: string) =>
    +date.substr(0, 4) >= new Date().getFullYear() - 18;

  const hasEnoughYouthPlayers = (
    registrations: typeof clubs[0]['registrations'] = [],
  ) =>
    registrations.filter(
      ({ member, regend }) =>
        regend > new Date().toLocaleDateString('sv-SE') &&
        isStudent(member.birthday),
    ).length >= 5;

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <TextField
            label="Klubb"
            value={team.clubid || ''}
            onChange={handleClubChange}
            select
            fullWidth
            disabled={!!teamProp}
            css={{ margin: `${spacing(1)}px 0` }}
          >
            {clubs.map(({ id, name, registrations }) => (
              <MenuItem
                key={id}
                value={id}
                disabled={
                  (oneTeamPerClub &&
                    teams.some((_team) => _team.clubid === id)) ||
                  !hasEnoughYouthPlayers(registrations)
                }
              >
                <ListItemText css={{ margin: 0 }}>{name}</ListItemText>

                {!hasEnoughYouthPlayers(registrations) && (
                  <ListItemSecondaryAction>
                    <Tooltip title="Du behöver ha minst 5 elver i klubben">
                      <Alert fontSize="small" css={{ color: yellow[800] }} />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Turnering"
            value={team.groupid || ''}
            onChange={handleTournamentChange}
            select
            fullWidth
            disabled={!team.clubid || !!teamProp}
          >
            {groups.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          {!oneTeamPerClub && (
            <TextField
              margin="dense"
              label="Lagnamn"
              value={team.name || ''}
              onChange={handleNameChange}
              fullWidth
              disabled={!team.clubid || !!teamProp}
            />
          )}
        </CardContent>
      </Card>
      <Card
        css={{ marginTop: spacing(2), position: 'relative', minHeight: 300 }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell size="medium">Medlemmar</TableCell>
              <TableCell align="center">Lagledare</TableCell>
              <TableCell align="center">Spelare</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {team.clubid && !persons.length && (
              <TableRow>
                <TableCell colSpan={3}>Inga medlemmar hittades</TableCell>
              </TableRow>
            )}
            {persons.map((person) => (
              <TableRow key={person.id}>
                <PersonCell>{person.name}</PersonCell>
                <PersonCell align="center">
                  <Checkbox
                    checked={person.isTeamLeader}
                    onChange={handleTeamLeaderChange(person)}
                    disabled={
                      !team.clubid ||
                      isStudent(person.birthday) ||
                      (user && user.id === person.id)
                    }
                  />
                </PersonCell>
                <PersonCell align="center">
                  <Checkbox
                    checked={person.isPlayer}
                    onChange={handlePlayerChange(person)}
                    disabled={
                      !team.clubid ||
                      !isStudent(person.birthday) ||
                      playersInOtherTeams[person.id]
                    }
                  />
                </PersonCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button
          type="submit"
          disabled={!persons.length || !team.clubid || !team.groupid}
          variant="contained"
          color="primary"
          css={{ margin: `${spacing(1)}px 0` }}
        >
          Spara
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  );
};
