import React, { useState } from 'react';

import {
  BackButton,
  IRegAccount,
  Loading,
  RegCheck,
  RegCheckProps,
  RegCreateAccount,
  RegLogin,
  RegOverview,
} from '..';
import regSettings from '../../../../settings/registration.json';
import {
  RegContextProvider,
  TournamentsContext,
  UserContext,
} from '../../contexts';

export function Reg() {
  const [account, setAccount] = useState<IRegAccount>();
  const { token } = React.useContext(UserContext);
  const { tournaments, isLoading } = React.useContext(TournamentsContext);

  const handleAccount: RegCheckProps['onAccount'] = (_account) => {
    setAccount(_account);
  };

  const buttons = (
    <BackButton
      css={{
        marginRight: 'auto',
      }}
      onBack={() => {
        setAccount(undefined);
      }}
    />
  );

  return isLoading || !tournaments ? (
    <Loading
      css={{
        height: 'calc(100vh - 64px)',
        background: 'initial',
      }}
    />
  ) : token ? (
    <RegContextProvider>
      <RegOverview />
    </RegContextProvider>
  ) : regSettings.createAccounts && !account ? (
    <RegCheck onAccount={handleAccount} />
  ) : regSettings.createAccounts && account && !account.id ? (
    <RegCreateAccount {...{ member: account, buttons }} />
  ) : (
    <RegLogin {...{ ...account, buttons }} />
  );
}
