import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useState } from 'react';

import site from '../../settings/site.json';
import SisLogoApple from '../assets/sis-logo-apple.svg';
import { CascadingMenu, LoginDialog } from '../old/components';
import { useUser } from '../old/contexts';
import { Page } from '../templates/page';

export const Header: React.FC<{ pages: Page[] }> = ({ children, pages }) => {
  const pagesObject = Object.fromEntries(
    pages.map(({ title, path }) => [path, title]),
  );

  const { token, setToken } = useUser();
  const [isLoginDialogOpen, setLoginDialog] = useState(false);

  function toggleLogin() {
    if (token) {
      setToken(undefined);
      setLoginDialog(false);
    } else {
      setLoginDialog(!isLoginDialogOpen);
    }
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <AppBar elevation={0} position="sticky">
        <Toolbar>
          <Typography variant="h2" component="div" color="inherit">
            <Link
              to="/"
              css={{
                color: 'inherit',
                textDecoration: 'none',
                display: 'flex',
              }}
            >
              <SisLogoApple
                fill="currentColor"
                css={{ minWidth: 24, marginRight: 4 }}
              />
              {site.title}
            </Link>
          </Typography>
          <div css={{ flexGrow: 1 }} />

          {site.menu.map((item) => {
            if ('subMenu' in item) {
              return (
                <CascadingMenu
                  key={item.title}
                  menu={{
                    ...item,
                    Component: Button,
                    // @ts-ignore
                    componentProps: () => ({ color: 'inherit' }),
                    menuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: -8,
                      },
                    },
                  }}
                />
              );
            }

            const title = item.title || pagesObject[item.page || ''] || '';
            const link = item.link || item.page || '';

            return (
              <Link
                to={link}
                key={link}
                css={{
                  color: 'inherit',
                  textDecoration: 'none',
                  margin: '0 8px',
                }}
              >
                <Button color="inherit">{title}</Button>
              </Link>
            );
          })}
          <div css={{ flexGrow: 1 }} />
          <Button
            css={{
              color: 'inherit',
              textDecoration: 'none',
              margin: '0 8px',
            }}
            onClick={toggleLogin}
          >
            {token ? 'Logga ut' : 'Logga in'}
          </Button>
        </Toolbar>
      </AppBar>
      <LoginDialog open={isLoginDialogOpen} onClose={toggleLogin} />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          backgroundImage: `url(${site.background})`,
          backgroundSize: 'contain',
          backgroundPosition: '50% 0',
        }}
      >
        {children}
      </div>
    </div>
  );
};
