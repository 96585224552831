import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Link } from 'gatsby';
import React from 'react';

import {
  ConditionalWrapper,
  ContenderCell,
  FlexRow,
  GroupResponse,
  ScrollShadow,
} from '..';

type Contender = GroupResponse['contenders'][0];
type Pairing = GroupResponse['pairings'][0];

interface StandingProps {
  group: GroupResponse;
  round: number;
  onContenderClick(contender: Contender): (event: React.MouseEvent) => void;
}

const NameCell = styled.div({
  minWidth: 144,
});

const ResultCell = styled.div({
  minWidth: 96,
  whiteSpace: 'nowrap',
});

const showDate = ({
  homeresult,
  awayresult,
  date,
  finalized,
}: Pick<Pairing, 'homeresult' | 'awayresult' | 'date' | 'finalized'>) =>
  !homeresult && !awayresult && !!date && !finalized;

export const Pairing: React.FC<StandingProps> = ({
  group,
  round,
  onContenderClick,
}) => {
  const pairings = group.pairings
    .filter((pairing) => !round || pairing.round === round)
    .map<Pairing & { home?: Contender; away?: Contender }>((pairing) => ({
      ...pairing,
      home: group.contenders.find(({ team }) => team.id === pairing.homeid),
      away: group.contenders.find(({ team }) => team.id === pairing.awayid),
    }));

  return (
    <ScrollShadow>
      <FlexRow head>
        <div className="icon" />
        <NameCell className="flex ellipsis">Hemmalag</NameCell>
        <div className="icon" />
        <NameCell className="flex ellipsis">Bortalag</NameCell>
        <ResultCell className="center">
          {pairings.some(showDate) ? 'Spelas senast' : 'Resultat'}
        </ResultCell>
      </FlexRow>

      {pairings.map(
        ({ id, home, away, note, homeresult, awayresult, date, finalized }) => (
          <Link
            key={id}
            to={`/matcher?match=${id}`}
            css={{
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <FlexRow hover>
              <div className="icon">
                <IconButton
                  size="small"
                  onClick={home && onContenderClick(home)}
                >
                  <InfoOutlined />
                </IconButton>
              </div>
              <ContenderCell contender={home} />
              <div className="icon">
                <IconButton
                  size="small"
                  onClick={away && onContenderClick(away)}
                >
                  <InfoOutlined />
                </IconButton>
              </div>
              <ContenderCell contender={away} />
              <ResultCell className="center">
                <ConditionalWrapper
                  condition={!!note.trim()}
                  wrapper={(children) => (
                    <Tooltip title={note} aria-label="wins">
                      <span>{children}*</span>
                    </Tooltip>
                  )}
                >
                  <span>
                    {showDate({ homeresult, awayresult, date, finalized })
                      ? date
                      : `${homeresult} - ${awayresult}`}
                  </span>
                </ConditionalWrapper>
              </ResultCell>
            </FlexRow>
          </Link>
        ),
      )}
    </ScrollShadow>
  );
};
