import React from 'react';
import { useY2CRequest } from '../old/hooks';
import { playingYear } from '@ssf/y2c-helpers';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';

export const Unregister = () => {
  const { request } = useY2CRequest();
  const [dialogText, setDialogText] = React.useState('');

  const handleCloseDialog = () => {
    setDialogText('');
  };

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const unsubscribe = url.searchParams.get('unsubscribe');
    if (unsubscribe) {
      url.searchParams.delete('unsubscribe');
      window.history.replaceState({}, document.title, url.toString());

      setDialogText(
        'Vi har nu registrerat att du inte ska ha några automatiska mail angående din anmälan till turneringen',
      );

      request({
        method: 'POST',
        url: '/status',
        headers: { Authorization: `Bearer ${unsubscribe}` },
        data: {
          tournament: `y2c-${playingYear}`,
          emailSubscribe: 'unsubscribed',
        },
      });
    }

    const unregister = url.searchParams.get('unregister');
    if (unregister) {
      url.searchParams.delete('unregister');
      window.history.replaceState({}, document.title, url.toString());

      setDialogText('Vi har nu plockat bort dig från turneringen');

      request({
        method: 'POST',
        url: '/status',
        headers: { Authorization: `Bearer ${unregister}` },
        data: {
          tournament: `y2c-${playingYear}`,
          removed: true,
        },
      });
    }
  }, []);

  return (
    <Dialog open={!!dialogText} onClose={handleCloseDialog}>
      <DialogContent>
        <Typography>{dialogText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Stäng</Button>
      </DialogActions>
    </Dialog>
  );
};
