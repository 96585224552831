import { Box, BoxProps, CircularProgress } from '@material-ui/core';
import React from 'react';

export const Loading = (props: BoxProps) => (
  <Box
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom={0}
    bgcolor="rgba(255,255,255,.9)"
    {...props}
  >
    <Box
      position="absolute"
      top="calc(50% - 20px)"
      left="calc(50% - 20px)"
      clone
    >
      <CircularProgress />
    </Box>
  </Box>
);
