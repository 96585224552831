import { memdbRequest, y2cRequest } from '.';

export interface Tournament {
  id: number;
  name: string;
  classes: {
    id: number;
    name: string;
    groups: Group[];
  }[];
}
export interface Group {
  id: number;
  name: string;
}

export interface Role {
  orgtype: number;
  orgid: number;
  orgrole: number;
  memberid: number;
  start: string;
  end: string;
  seclevel: number;
  description: string;
}

export interface Member {
  id: number;
  firstname: string;
  lastname: string;
  birthday: string;
  sex: number;
  street: string;
  zipcode: number;
  city: string;
  email?: string;
  phone?: string;
}

export interface Club {
  id: number;
  name: string;
  active: number;
  school?: School;

  street?: string;
  zipcode?: number;
  city?: string;
  noeconomy?: number;
  schoolclub?: number;
  schoolname?: string;
  schoolid?: number;
  countyid?: number;
  vbdescr?: string;
  schoolclubreason?: number;
  phonenr?: string;
  email?: string;
  startdate?: string;
  startseason?: string;
  endseason?: string;

  checkvalues?: { year: number; referenceid: number }[];

  roles?: Role[];
  registrations?: {
    regstart: string;
    regend: string;
    date: string;
    membershiptype: number;
    fee: number;
    member: Pick<Member, 'id' | 'firstname' | 'lastname' | 'birthday'>;
  }[];
}

export interface School {
  id: number;
  name: string;
  street: string;
  zipcode: number;
  city: string;
  districtid: number;
  countyid: number;
  breakyear?: number;
  springbreak?: number;
  easterbreak?: number;
}
export interface Team {
  id: number;
  name: string;
  clubid: number;
  groupid: number;
  roles: Pick<Role, 'memberid' | 'start'>[];
  players: { member: Pick<Member, 'id' | 'sex'> }[];
}

interface Status {
  status: number;
  message: string;
}

export interface RegStatus {
  account?: {};
  accountStatus: Status;
  school?: School;
  schoolStatus: Status;
  clubs?: Club[];
  clubsStatus: Status;
  teams?: Team[];
  players?: number;
  teamsStatus: Status;
  documents?: { [key: number]: string };
  documentsStatus: Status;
  verifications?: {};
  verificationsStatus: Status;
}

export interface ApiEmail {
  messageId: string;
  timestamp: number;
  category: string;
  to: string;
  subject: string;
  html: string;
  events: {
    timestamp: number;
    event: string;
  }[];
}

export interface ApiStatus {
  user: number;
  name?: string;
  school?: string;
  districtid?: number;
  nClubs: number;
  nTeams: number;
  tournament: string;
  created: string;
  updated: string;
  comment: string;
  status: {
    school: number;
    clubs: number;
    teams: number;
    documents: number;
    verifications: number;
  };
  reason?: number;
  emails: ApiEmail[];
  emailSubscribe: 'opted_in' | 'unsubscribed' | 'subscribed';
  removed: boolean;
}

export const defaultStatus = {
  accountStatus: { status: 3, message: '' },
  schoolStatus: { status: 1, message: 'Klicka här för att välja skola' },
  clubsStatus: { status: 0, message: '' },
  teamsStatus: { status: 0, message: '' },
  documentsStatus: { status: 0, message: '' },
  verificationsStatus: { status: 0, message: '' },
};

export const playingYear = new Date(
  Date.now() + 1000 * 60 * 60 * 24 * 30 * 6,
).getFullYear();

export function getSchoolStatus(school: School) {
  const okBreakyear = school.breakyear && school.breakyear >= playingYear;
  if (!okBreakyear) {
    // eslint-disable-next-line no-param-reassign
    school.springbreak = undefined;
    // eslint-disable-next-line no-param-reassign
    school.easterbreak = undefined;
  }

  return {
    schoolStatus:
      okBreakyear && !!school.springbreak && !!school.easterbreak
        ? {
            status: 3,
            message: '',
          }
        : !okBreakyear || (!school.springbreak && !school.easterbreak)
        ? {
            status: 1,
            message: 'Klicka här för att välja lovveckor',
          }
        : !school.springbreak
        ? {
            status: 1,
            message: 'Du behöver välja sportlovsvecka',
          }
        : !school.easterbreak
        ? {
            status: 1,
            message: 'Du behöver välja påsklovsvecka',
          }
        : defaultStatus.schoolStatus,
    school,
  };
}

export function getClubsStatus(clubs: Club[], school?: School, teams?: Team[]) {
  const incompleteBoard = clubs.some(
    (club) =>
      (club.roles || []).reduce(
        (tot, { orgrole }) =>
          tot |
          (orgrole === 100 ? 1 : orgrole === 101 ? 2 : orgrole === 102 ? 4 : 0),
        0,
      ) !== 7 && (teams || []).some(({ clubid }) => club.id === clubid),
  );

  const haveAccountant = clubs.some(({ roles }) =>
    roles?.some(({ orgrole }) => orgrole === 108),
  );

  return {
    clubsStatus:
      !clubs.length && school
        ? {
            status: 1,
            message: 'Klicka här för att skapa en klubb',
          }
        : !clubs.length
        ? defaultStatus.clubsStatus
        : clubs.some(
            (club) =>
              (!club.registrations || club.registrations.length < 5) &&
              (teams || []).some(({ clubid }) => club.id === clubid),
          )
        ? {
            status: 1,
            message:
              clubs.length === 1
                ? 'Klubben har inte tillräckligt många medlemmar'
                : 'En eller flera klubbar har inte tillräckligt många medlemmar',
          }
        : incompleteBoard
        ? {
            status: 1,
            message:
              clubs.length === 1
                ? 'Klubben har ofullständig styrelse'
                : 'En eller flera klubbar har ofullständig styrelse',
          }
        : !haveAccountant
        ? {
            status: 1,
            message:
              clubs.length === 1
                ? 'Klubben har ingen revisor'
                : 'En eller flera klubbar har ingen revisor',
          }
        : !teams || !teams.length
        ? {
            status: 1,
            message: 'Du har inga klubbar anmäld till turneringen',
          }
        : {
            status: 3,
            message: '',
          },
    clubs,
  };
}

export function getTeamsStatus(teams: Team[], clubs: Club[] = []) {
  return {
    teamsStatus: !clubs.length
      ? defaultStatus.teamsStatus
      : !teams.length
      ? {
          status: 1,
          message: 'Klicka här för att göra en anmälan',
        }
      : teams.some((team) => team.players.length < 5)
      ? {
          status: 1,
          message: `${
            teams.length ? 'Alla klubbar' : 'Klubben'
          } måste bestå av minst fem spelare`,
        }
      : // eslint-disable-next-line no-bitwise
      teams.some(
          (team) =>
            team.players.reduce(
              (value, player) => value | (player.member.sex + 1),
              0,
            ) !== 3,
        )
      ? {
          status: 1,
          message: `${
            teams.length ? 'Alla klubbar' : 'Klubben'
          } måste bestå av minst en tjej, och en kille`,
        }
      : teams.some((team) => !team.roles.length)
      ? {
          status: 1,
          message: `${
            teams.length ? 'Alla klubbar' : 'Klubben'
          } måste ha en lagledare`,
        }
      : {
          status: 3,
          message: '',
        },
    teams,
  };
}

export function getDocumentsStatus(
  documents: RegStatus['documents'] = {},
  clubs: Club[] = [],
  teams: Team[] = [],
) {
  const clubsWithTeam = teams.reduce<{ [key: number]: true }>(
    (obj, { clubid }) => ({ ...obj, [clubid]: true }),
    {},
  );

  return {
    documentsStatus: !clubs.length
      ? defaultStatus.documentsStatus
      : !Object.keys(clubsWithTeam).length
      ? {
          status: 1,
          message: `Börja med att anmäla ${
            clubs.length > 1 ? 'klubbar' : 'klubben'
          } till turneringen`,
        }
      : clubs.every(
          (club) =>
            !clubsWithTeam[club.id] ||
            (+(club.startdate || '').substr(0, 4) < playingYear - 1
              ? !!(club.checkvalues || []).length
              : club.active === 1),
        )
      ? {
          status: 3,
          message: '',
        }
      : clubs.every((club) => !clubsWithTeam[club.id] || documents[club.id])
      ? {
          status: 2,
          message: 'Vi inväntar era dokument, och hör av oss när de inkommit',
        }
      : {
          status: 1,
          message: 'Klicka här för att skapa de dokument som behövs',
        },
    documents,
  };
}

export function getVerificationsStatus(clubs: Club[], teams: Team[]) {
  const players: { [key: string]: boolean } = Object.fromEntries(
    teams.flatMap((team) =>
      team.players.map(({ member }) => [`${team.clubid}-${member.id}`, true]),
    ),
  );

  const regs = clubs.flatMap(({ id, registrations }) =>
    (registrations || []).filter(({ member }) => players[`${id}-${member.id}`]),
  );

  return {
    verificationsStatus: !clubs.some(
      ({ registrations }) => registrations && registrations.length,
    )
      ? defaultStatus.verificationsStatus
      : !regs.length
      ? {
          status: 1,
          message: 'Ange spelare innan du börjar verifiera medlemmar',
        }
      : regs.every(
          ({ membershiptype, fee }) => membershiptype >= 120 && fee > 0,
        )
      ? {
          status: 1,
          message: 'Klicka här för att skapa medlemsintyganden',
        }
      : regs.some(({ membershiptype, fee }) => membershiptype >= 120 && fee > 0)
      ? {
          status: 1,
          message: 'Alla intyganden är inte utskrivna',
        }
      : regs.some(({ membershiptype, fee }) => membershiptype >= 120 && fee < 0)
      ? {
          status: 2,
          message: 'Skicka in alla intyganden och vänta på godkännande',
        }
      : {
          status: 3,
          message: '',
        },
  };
}

export const getRegStatus = (status: RegStatus): RegStatus => {
  const { school, schoolStatus } = status.school
    ? getSchoolStatus(status.school)
    : status;
  const { clubs, clubsStatus } =
    status.clubs || status.school || status.teams
      ? getClubsStatus(status.clubs || [], school, status.teams || [])
      : status;
  const { teams, teamsStatus } =
    status.teams || status.clubs
      ? getTeamsStatus(status.teams || [], clubs)
      : status;
  const { documents, documentsStatus } =
    status.documents || status.clubs || status.teams
      ? getDocumentsStatus(status.documents, clubs, teams)
      : status;
  const { verificationsStatus } =
    status.clubs || status.teams
      ? getVerificationsStatus(
          clubs || status.clubs || [],
          teams || status.teams || [],
        )
      : status;

  return {
    ...status,
    school,
    schoolStatus,
    clubs,
    clubsStatus,
    teams,
    teamsStatus,
    documents,
    documentsStatus,
    verificationsStatus,
  };
};

export const statusRequest = ({
  memdbUrl,
  y2cApiUrl,
  token,
  tournaments,
  memberId,
}: {
  memdbUrl: string;
  y2cApiUrl: string;
  token: string;
  tournaments: Tournament[];
  memberId?: number | string | null;
}): Promise<Partial<RegStatus>[]> =>
  Promise.all([
    memdbRequest<{
      roles: Pick<Role, 'orgtype' | 'orgrole' | 'end'> & { club: Club }[];
    }>({
      url: `${memdbUrl}/api/v2/member/${memberId ?? 'me'}`,
      headers: {
        Token: token,
      },
      params: {
        schema: {
          roles: [
            {
              orgtype: 1,
              orgrole: 1,
              end: 1,
              club: {
                id: 1,
                name: 1,
                active: 1,
                startdate: 1,
                schoolid: 1,
                schoolclub: 1,
                schoolclubreason: 1,
                comment: 1,
                changed: 1,
                school: {
                  id: 1,
                  name: 1,
                  street: 1,
                  zipcode: 1,
                  city: 1,
                  districtid: 1,
                  countyid: 1,
                  comment: 1,
                  breakyear: 1,
                  springbreak: 1,
                  easterbreak: 1,
                },
                roles: [
                  {
                    memberid: 1,
                    end: 1,
                    orgrole: 1,
                    filter: '(&(end=1970-01-01)(|(orgrole=150)(orgrole<=102)(orgrole=108)))',
                  },
                ],
                registrations: [
                  {
                    member: { id: 1, firstname: 1, lastname: 1, birthday: 1 },
                    regstart: 1,
                    regend: 1,
                    date: 1,
                    fee: 1,
                    membershiptype: 1,
                    filter: `(regstart>=${new Date(
                      Date.now() - 1000 * 60 * 60 * 24 * 30 * 8,
                    ).getFullYear()}-01-01)`, // -8 month
                  },
                ],
                // referenceid 2 = Fått årsmötesprotokoll
                checkvalues: [
                  {
                    year: 1,
                    referenceid: 1,
                    filter: `(&(year=${playingYear - 1})(referenceid=2))`,
                  },
                ],
              },
              filter: '(&(orgtype=3)(orgrole=150)(club.schoolclub=1)(end=1970-01-01))',
            },
          ],
        },
      },
    }).then<Partial<RegStatus>>((response) => {
      if (response && response.data) {
        const { roles } = response.data;

        return {
          school: roles.length
            ? roles[roles.length - 1].club.school
            : undefined,
          clubs: roles.map(({ club }) => club),
        };
      }

      return {};
    }),
    Promise.all(
      tournaments.flatMap(({ classes }) =>
        classes.flatMap(({ groups }) =>
          groups.flatMap(({ id }) =>
            memdbRequest<Team[]>({
              url: `${memdbUrl}/api/v2/tournament/team`,
              headers: {
                Token: token,
              },
              params: {
                ...(memberId && { memberid: memberId }),
                groupid: id,
                schema: {
                  id: 1,
                  name: 1,
                  clubid: 1,
                  roles: [
                    {
                      memberid: 1,
                      start: 1,
                      end: 1,
                      filter: '(end=1970-01-01)',
                    },
                  ],
                  players: [{ member: { id: 1, sex: 1 } }],
                },
              },
            }).then((response) =>
              response && response.data
                ? response.data.map((team) => ({ ...team, groupid: id }))
                : [],
            ),
          ),
        ),
      ),
    ).then<Partial<RegStatus>>((teamGroups) => {
      return {
        teams: teamGroups.flatMap((teams) => teams),
      };
    }),
    y2cRequest<
      | {
          result: {
            school: RegStatus['school'];
            prints: RegStatus['documents'];
          };
        }
      | undefined
    >({
      url: `${y2cApiUrl}/store${memberId ? `/${memberId}` : ''}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const ret: Partial<RegStatus> = {};
      if (response?.data?.result) {
        ret.documents = response.data.result.prints || {};
        if (response.data.result.school) {
          ret.school = response.data.result.school;
        }
      }

      return ret;
    }),
  ]);
