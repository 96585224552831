import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { AxiosResponse } from 'axios';
import React from 'react';

import { useY2CRequest } from '../hooks';
import { Loading } from '.';

export function RecoverPassword({
  buttonProps,
  email = '',
}: {
  buttonProps?: ButtonProps;
  email?: string;
}) {
  const [isOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isSubmitted, setSubmitted] = React.useState(false);
  const { request, isLoading } = useY2CRequest();

  function handleToggleOpen() {
    setOpen(!isOpen);
    setError('');
    setSubmitted(false);
  }

  async function handleSubmit(
    event: React.FormEvent<HTMLFormElement & { email: HTMLInputElement }>,
  ) {
    event.preventDefault();
    event.stopPropagation();

    const { value } = event.currentTarget.email;

    if (value) {
      request({
        method: 'POST',
        url: '/password-reset',
        data: {
          email: value,
        },
      })
        .then(() => {
          setSubmitted(true);
        })
        .catch((e: { response: AxiosResponse<{ error: string }> }) => {
          setError(e.response.data.error);
        });
    }
  }

  return (
    <>
      <Button onClick={handleToggleOpen} {...buttonProps}>
        Glömt lösenord
      </Button>
      <Dialog open={isOpen} onClose={handleToggleOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Glömt lösenord</DialogTitle>
          {isSubmitted ? (
            <>
              <DialogContent>
                <Typography>
                  Vi har nu skickat ett mail till dig från yes2chess@schack.se.
                  Kolla din inbox och följ instruktionerna i mailet.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleToggleOpen}>
                  Stäng
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogContent>
                <Typography>
                  Fyll i fältet nedan så skickas ett mail till dig med
                  instruktioner hur du byter lösenord.
                </Typography>
                <TextField
                  name="email"
                  defaultValue={email}
                  autoFocus
                  margin="dense"
                  label="Email"
                  type="text"
                  fullWidth
                  error={!!error}
                  helperText={error}
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" color="primary">
                  Skicka
                </Button>
              </DialogActions>
            </>
          )}
        </form>
        {isLoading && <Loading />}
      </Dialog>
    </>
  );
}
