import { AxiosResponse } from 'axios';

import { Club, memdbRequest, Registration, Schema, UnSchema } from '.';

export interface Member {
  id: number;
  firstname: string;
  lastname: string;
  birthday: string;
  sex: number;
  street: string;
  zipcode: number;
  city: string;
  email: string;
  phone: string;

  name: string;

  club: Club;

  registrations: Registration[];
}

export function getMember<
  T extends Member,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(id: number | 'me', schema: S, token = '') {
  return memdbRequest.get<U>(`/member/${id}`, {
    params: { schema },
    headers: { token },
  });
}

export function getMembers<
  T extends Member,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(params: { email: string; schema: S }): Promise<AxiosResponse<U[]>>;
export function getMembers<
  T extends Member,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(params: {
  name: string;
  zipcode?: number;
  limit?: number;
  offset?: number;
  schema: S;
}): Promise<AxiosResponse<U[]>>;
export function getMembers<
  T extends Member,
  S extends Schema<T>,
  U extends UnSchema<T, S>
>(params: object & { schema: U }): Promise<AxiosResponse<U[]>> {
  return memdbRequest.get<U[]>('/member', { params });
}

export function setMemberPassword(
  id: number | string,
  password: string,
  token: string,
) {
  const body = new URLSearchParams({
    password,
    password2: password,
  });

  return memdbRequest.post<number>(`/member/${id}`, body, {
    headers: { token },
  });
}

export const login = async ({
  email,
  password,
  origin = 'ssf-play',
}: {
  email: string;
  password: string;
  origin?: string;
}) => {
  const [value, loginAs] = email.split(':');
  const body = new URLSearchParams({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [isNaN(value as any) ? 'email' : 'id']: value,
    password,
  });

  if (loginAs) {
    body.set('loginAs', loginAs);
  }

  return memdbRequest.post<{ result: string }>('/login', body, {
    headers: { origin },
  });
};
