import React, { useContext, useState } from 'react';

export interface IUserContext {
  token?: string;
  setToken: React.Dispatch<string | undefined>;
}

export const UserContext = React.createContext<IUserContext>({
  setToken: () => {},
});

export const UserContextProvider: React.FC = (props) => {
  const [token, setToken] = useState<string | undefined>();

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
      }}
      {...props}
    />
  );
};

export const useUser = () => useContext(UserContext);
