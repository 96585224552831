import { IconButton, InputAdornment } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

export const Password: React.FC<Pick<
  TextFieldProps,
  'autoFocus' | 'label' | 'error' | 'helperText' | 'fullWidth'
>> = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  function handleClick(): void {
    setShowPassword(!showPassword);
  }

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      name="password"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={handleClick}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
