import { Global } from '@emotion/core';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { Environment } from '@ssf/components';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Unregister } from '.';

import { theme } from '../lib/theme';

export const Layout: React.FC = ({ children }) => (
  <MUIThemeProvider theme={theme}>
    <Global
      styles={{
        body: {
          margin: 0,
        },
        'html, body, #___gatsby, #gatsby-focus-wrapper': {
          height: '100%',
        },
      }}
    />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <EmotionThemeProvider theme={theme}>
      {children}
      <Unregister />
      <Environment />
    </EmotionThemeProvider>
  </MUIThemeProvider>
);
