import React, { DOMAttributes, useState, useContext } from 'react'
import { Card, TextField, Divider, CardContent, Button, useTheme, Theme, MenuItem, Paper, LinearProgress } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import Downshift, { DownshiftProps } from 'downshift'
import { useDebouncedCallback } from 'use-debounce'
import { School, playingYear, getSchoolStatus } from '@ssf/y2c-helpers'

import { ButtonRow, Loading, TextBubble } from '..'
import { useMemdbRequest, useY2CRequest } from '../../hooks'
import { UserContext, useRegStatus } from '../../contexts'

export function RegSchool({ buttons }: { buttons: React.ReactNode }) {
  const { spacing } = useTheme<Theme>()
  const { user } = useContext(UserContext)
  const { status, setStatus } = useRegStatus()

  const [school, setSchool] = useState(status.school)
  const [schools, setSchools] = useState<School[]>([])
  const { request: requestSchool, isLoading: isLoadingSchool } = useMemdbRequest()
  const { request, isLoading } = useMemdbRequest()
  const { request: y2cRequest } = useY2CRequest()

  const [handleInputChange] = useDebouncedCallback<NonNullable<DownshiftProps<School>['onInputValueChange']>>(async value => {
    // todo: sends request when changing schoolbreak
    const response = await requestSchool<School[]>({
      url: '/school/name',
      params: {
        name: `%${value}%`,
        zipcode: user ? user.zipcode : 0,
        schema: {
          id: 1,
          name: 1,
          street: 1,
          zipcode: 1,
          city: 1,
          districtid: 1,
          countyid: 1,
          breakyear: 1,
          springbreak: 1,
          easterbreak: 1
        },
        limit: 50
      }
    })

    setSchools(response ? response.data : [])
  }, 200)

  const handleChange: DownshiftProps<School>['onChange'] = newSchool => {
    if (newSchool) {
      setSchool(getSchoolStatus(newSchool).school)
    }
  }

  const handleSpringbreakChange: TextFieldProps['onChange'] = ({ target }) => {
    if (school) {
      setSchool({ ...school, breakyear: playingYear, springbreak: +target.value })
    }
  }

  const handleEasterbreakChange: TextFieldProps['onChange'] = ({ target }) => {
    if (school) {
      setSchool({ ...school, breakyear: playingYear, easterbreak: +target.value })
    }
  }

  const handleSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = event => {
    event.preventDefault()

    // todo: error handling
    if (school) {
      request<{ result: number }>({
        method: 'POST',
        url: `/school/${school.id}/break`,
        data: {
          breakyear: playingYear,
          springbreak: school.springbreak || 0,
          easterbreak: school.easterbreak || 0
        }
      }).then(response => {
        if (response && response.data) {
          y2cRequest({
            method: 'POST',
            url: '/store',
            data: { school }
          })
          setStatus({ school }, ['overview'])
        }
      })
    }
  }

  const springBreak = [7, 8, 9, 10, 11]
  const easterBreak = [12, 13, 14, 15, 16, 17]

  return (
    <form onSubmit={handleSubmit}>
      <TextBubble>
        Nu ska du fylla i skolans namn. Börja skriva in namnet och välj sedan namn från listan. Glöm inte att fylla i lovveckor!
      </TextBubble>
      <Card css={{ overflow: 'initial', position: 'relative' }}>
        <CardContent>
          <Downshift
            onInputValueChange={handleInputChange}
            itemToString={item => (item ? item.name : '')}
            selectedItem={school || null}
            onChange={handleChange}
          >
            {({ getInputProps, getItemProps, getLabelProps, getMenuProps, isOpen }) => {
              const { onBlur, onFocus, ...inputProps } = getInputProps()
              return (
                <div>
                  <TextField
                    autoFocus
                    label="Skola"
                    fullWidth
                    InputProps={{ onBlur, onFocus, inputProps }}
                    InputLabelProps={{ shrink: !!school || undefined, ...getLabelProps() }}
                  />
                  <div css={{ position: 'relative' }} {...getMenuProps()}>
                    {isOpen && (
                      <Paper
                        css={{
                          position: 'absolute',
                          zIndex: 1,
                          marginTop: spacing(1),
                          left: 0,
                          right: 0,
                          maxHeight: 360,
                          minHeight: 20,
                          overflow: 'auto'
                        }}
                      >
                        {isLoadingSchool && <LinearProgress css={{ position: 'absolute', width: '100%' }} />}
                        {!isLoadingSchool && !schools.length && <div>ingen skola hittad</div>}
                        {schools.map(_school => (
                          <MenuItem {...getItemProps({ item: _school })} key={_school.id}>
                            {_school.name}, {_school.city}
                          </MenuItem>
                        ))}
                      </Paper>
                    )}
                  </div>
                </div>
              )
            }}
          </Downshift>
        </CardContent>
        <Divider />
        <CardContent
          css={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            label="Sportlov"
            value={(school && school.springbreak) || false}
            select
            css={{ width: 'calc(50% - 12px)' }}
            onChange={handleSpringbreakChange}
            disabled={!school}
            InputLabelProps={{ shrink: (school && !!school.springbreak) || false }}
          >
            {springBreak.map(week => (
              <MenuItem key={week} value={week}>
                v.{week}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Påsklov"
            value={(school && school.easterbreak) || false}
            select
            css={{ width: 'calc(50% - 12px)' }}
            onChange={handleEasterbreakChange}
            disabled={!school}
            InputLabelProps={{ shrink: (school && !!school.easterbreak) || false }}
          >
            {easterBreak.map(week => (
              <MenuItem key={week} value={week}>
                v.{week}
              </MenuItem>
            ))}
          </TextField>
        </CardContent>
        {isLoading && <Loading />}
      </Card>
      <ButtonRow>
        <Button type="submit" variant="contained" color="primary" disabled={isLoading} css={{ margin: `${spacing(1)}px 0` }}>
          Spara
        </Button>
        {buttons}
      </ButtonRow>
    </form>
  )
}
