import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import rehypeReact from 'rehype-react';
import parse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import unified, { Transformer } from 'unified';

const h1 = (props: React.ComponentProps<typeof Typography>) => (
  <Typography variant="h1" css={{ margin: '12px 0' }} {...props} />
);
const h2 = (props: React.ComponentProps<typeof Typography>) => (
  <Typography variant="h2" css={{ margin: '12px 0' }} {...props} />
);
const h3 = (props: React.ComponentProps<typeof Typography>) => (
  <Typography
    variant="h3"
    color="primary"
    css={{ marginBottom: -12 }}
    {...props}
  />
);
const li = (variant: TypographyProps['variant'] = 'body1') => (
  props: Omit<React.ComponentProps<typeof Typography>, 'ref'>,
) => <Typography variant={variant} component="li" {...props} />;
const p = (variant: TypographyProps['variant'] = 'body1') => (
  props: React.ComponentProps<typeof Typography>,
) => <Typography variant={variant} css={{ margin: '12px 0' }} {...props} />;
const td = (variant: TypographyProps['variant'] = 'body1') => (
  props: React.ComponentProps<typeof Typography>,
) => <Typography variant={variant} component="td" {...props} />;
const em = (props: React.ComponentProps<typeof Typography>) => (
  <Typography variant="overline" {...props} />
);
const a = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a css={{ color: 'inherit' }} {...props} />
);
const table = (props: React.TableHTMLAttributes<HTMLTableElement>) => (
  <table css={{ width: '100%' }} {...props} />
);

function interpolate(template: string, params: object): string {
  return new Function(...Object.keys(params), `return \`${template}\`;`)(
    ...Object.values(params),
  );
}

const parser = (variant: TypographyProps['variant'], values?: object) =>
  unified()
    .use(parse, { commonmark: true })
    .use(remarkRehype)
    .use((values?: object) => {
      const transformer: Transformer = (node) => {
        if (values) {
          const loop = (node: Parameters<Transformer>[0]) => {
            if (['root', 'element'].includes(node.type)) {
              node.children = (node.children as typeof node[]).map((child) =>
                loop(child),
              );
            }
            if (node.type === 'text') {
              node.value = interpolate(node.value as string, values);
            }

            return node;
          };

          loop(node);
        }
      };

      return transformer;
    }, values)
    .use(rehypeReact, {
      createElement: React.createElement,
      components: {
        h1,
        h2,
        h3,
        li: li(variant),
        table,
        td: td(variant),
        p: p(variant),
        em,
        a,
      },
    });

export const Markdown: React.FC<{
  markdown: string;
  variant?: TypographyProps['variant'];
  values?: object;
}> = ({ markdown, variant, values }) =>
  ((parser(variant, values).processSync(markdown) as unknown) as {
    result: React.ReactElement;
  }).result;
