import { Box, Typography } from '@material-ui/core';
import React from 'react';

export const Environment = () =>
  process.env.ENV !== 'production' ? (
    <Box
      position="fixed"
      bottom="-36px"
      right="-72px"
      width="192px"
      paddingBottom="96px"
      clone
      textAlign="center"
      bgcolor="#308150"
      color="#FFF"
    >
      {/* @ts-ignore */}
      <Typography
        component="div"
        css={{ transform: 'rotate(-45deg)', textTransform: 'uppercase' }}
      >
        {process.env.ENV}
      </Typography>
    </Box>
  ) : null;
