import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { getMember, Role } from '@ssf/memdb-api/src';
import { Club, Member } from '@ssf/y2c-helpers';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { ButtonRow, Loading, RegAndRole } from '..';
import { useRegStatus } from '../../contexts';
import { useMemdbRequest } from '../../hooks';
import {
  birthday,
  city,
  email as emailField,
  firstname,
  lastname,
  MemberFields,
  phone as phoneField,
  sex,
  street,
  TextFieldProps,
  useMemberFields,
  zipcode,
} from '../fields/MemberFields';

const date18 = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

const phone: TextFieldProps = {
  props: {
    ...phoneField.props,
    label: 'Telefonnummer (valfritt)',
  },
  schema: {
    ...phoneField.schema,
    medium: yup.string(),
    hard: yup
      .string()
      .test(
        'test-phonenumber',
        'Du måste ange ett korrekt svenskt telefonnummer',
        (value) => !value || parsePhoneNumber(value, 'SE').isValid(),
      ),
  },
};

const email: TextFieldProps = {
  props: { ...emailField.props, label: 'Email (valfritt)' },
  schema: {
    ...emailField.schema,
    medium: yup.string(),
    hard: yup.string().email(),
  },
};

const roleOptions: [React.ReactNode, number][] = [
  [<>&nbsp;</>, 0],
  ['Ordförande', Role.PRESIDENT],
  ['Sekreterare', Role.SECRETARY],
  ['Ledamot', Role.BOARDMEMBER],
  ['Revisor', Role.ACCOUNTANT],
];

const role: TextFieldProps = {
  props: {
    name: 'role',
    label: 'Styrelseroll (valfritt)',
    select: true,
    children: roleOptions.map(([label, value]) => (
      <MenuItem key={value.toString()} value={value}>
        {label}
      </MenuItem>
    )),
  },
  schema: {
    soft: yup.number(),
  },
};

const role18: TextFieldProps = {
  ...role,
  schema: {
    soft: yup
      .number()
      .test(
        'test-age',
        'Du måste vara över 18 år när du är ordförande',
        (value) => value !== 100,
      ),
  },
};

const defaultFields = {
  obj: {
    firstname,
    lastname,
    birthday,
    sex,
    street,
    zipcode,
    city,
    phone,
    email,
    role: role18,
  },
  fields: [
    [firstname, lastname, birthday, sex, street, zipcode, city, phone, email],
    [role18],
  ],
};

export function RegCreateUpdateMember({
  buttons,
  club,
  reg,
  onNewEditedReg,
}: {
  buttons: React.ReactNode;
  club: Club;
  reg?: RegAndRole;
  onNewEditedReg(reg: RegAndRole): void;
}) {
  const { spacing } = useTheme<Theme>();
  const { request } = useMemdbRequest();
  const [isLoading, setLoading] = useState(false);

  const [{ obj, fields }, setFields] = useState(defaultFields);

  const {
    status: { clubs = [] },
  } = useRegStatus();

  const [dupe, setDupe] = useState<{
    club: Club;
    member: Pick<Member, 'id' | 'firstname' | 'lastname' | 'birthday'>;
  }>();

  const {
    values,
    errors,
    setErrors,
    validate,
    onChange,
    onDateChange,
    onBlur,
  } = useMemberFields<Omit<Member, 'id'> & { role: number }>(
    reg
      ? {
          ...reg.member,
          role: reg.roles.reduce(
            (orgrole, _role) => orgrole || _role.orgrole,
            0,
          ),
        }
      : {},
    obj,
  );

  useEffect(() => {
    setFields(
      values.role !== null &&
        values.birthday &&
        (typeof values.birthday === 'string'
          ? values.birthday <= date18.toLocaleDateString('sv-SE')
          : values.birthday <= date18)
        ? {
            obj: { ...obj, phone: phoneField, email: emailField, role },
            fields: [
              [
                firstname,
                lastname,
                birthday,
                sex,
                street,
                zipcode,
                city,
                phoneField,
                emailField,
              ],
              [role],
            ],
          }
        : defaultFields,
    );
  }, [values.role, values.birthday]);

  useEffect(() => {
    if (!reg && values.firstname && values.lastname) {
      for (let club of clubs) {
        for (let { member } of club.registrations || []) {
          if (
            member.firstname === values.firstname &&
            member.lastname === values.lastname
          ) {
            setDupe({ club, member });
            return;
          }
        }
      }
    }
  }, [values.firstname, values.lastname]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    validate(
      {
        firstname: '',
        lastname: '',
        birthday: '',
        sex: -1,
        street: '',
        zipcode: -1,
        city: '',
        phone: '',
        email: '',

        ...values,
      },
      'hard',
    ).then(
      async ({
        values: { role: orgrole, birthday, ...otherValues },
        errors: _errors,
      }) => {
        if (Object.values(_errors).some((value) => !!value)) {
          setErrors(_errors);
          setLoading(false);
        } else {
          const member = {
            ...otherValues,
            birthday:
              birthday instanceof Date
                ? birthday.toLocaleDateString('sv-SE')
                : birthday,
          } as Omit<Member, 'id'>;

          const requestData = reg
            ? { url: `/member/${reg.member.id}`, data: { member } }
            : {
                url: '/member',
                data: {
                  member,
                  ignoreDupe: true,
                  clubid: club.id,
                  origin: 'yes2chess.se',
                },
              };
          const memberResponse = await request<{ result: number }>({
            method: 'POST',
            ...requestData,
          });
          if (
            memberResponse &&
            memberResponse.data &&
            memberResponse.data.result > 0
          ) {
            const memberId =
              (reg && reg.member.id) || memberResponse.data.result;
            const roles = reg ? [...reg.roles] : [];

            if (reg && reg.roles[0] && reg.roles[0].orgrole !== orgrole) {
              await request<{ result: number }>({
                method: 'POST',
                url: `/member/${memberId}/role`,
                data: {
                  role: {
                    ...reg.roles[0],
                    end: new Date().toLocaleDateString('sv-SE'),
                  },
                },
              });

              roles.shift();
            }

            if (orgrole) {
              const newRole = {
                orgtype: 3,
                orgrole: orgrole as number,
                start: new Date().toLocaleDateString('sv-SE'),
                end: '1970-01-01',
                orgid: club.id,
                seclevel: -1,
                memberid: memberId,
                description: '',
              };
              await request<{ result: number }>({
                method: 'POST',
                url: `/member/${memberId}/role`,
                data: {
                  role: newRole,
                },
              });

              roles.unshift(newRole);
            }

            setLoading(false);

            onNewEditedReg({
              regstart: `${new Date().getFullYear()}-01-01`,
              regend: `${new Date().getFullYear()}-12-31`,
              date: new Date().toLocaleDateString('sv-SE'),
              fee: 10,
              membershiptype: 130,
              ...reg,
              member: { id: memberId, ...member },
              roles,
            });
          }
        }
      },
    );
  }

  const handleAddMembership = async () => {
    if (dupe) {
      const response = await request<{ result: number }>({
        url: `/club/${club.id}/membership/${dupe.member.id}`,
        method: 'POST',
      });

      if (response && response.data && response.data.result > 0) {
        const { data: member } = await getMember(dupe.member.id, {
          id: 1,
          firstname: 1,
          lastname: 1,
          birthday: 1,
          sex: 1,
          street: 1,
          zipcode: 1,
          city: 1,
          email: 1,
          phone: 1,
        });
        onNewEditedReg({
          membershiptype: 130,
          fee: 10,
          date: new Date().toLocaleDateString('sv-SE'),
          regstart: `${new Date().getFullYear()}-01-01`,
          regend: `${new Date().getFullYear()}-12-31`,
          roles: [],
          member,
        });

        setDupe(undefined);
      }
    }
  };

  return (
    <Container css={{ marginBottom: 36, maxWidth: 600 + 24 * 2 }}>
      <form onSubmit={handleSubmit}>
        <Card css={{ position: 'relative' }}>
          <MemberFields
            {...{ fields, values, errors, onChange, onDateChange, onBlur }}
          />
          {isLoading && <Loading />}
        </Card>
        <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Spara
          </Button>
          {buttons}
        </ButtonRow>
      </form>
      <Dialog open={!!dupe}>
        <DialogTitle>Dubblett hittad</DialogTitle>
        <DialogContent>
          <Typography>
            Vi har hittat att {dupe?.member.firstname} {dupe?.member.lastname}{' '}
            även finns i klubben {dupe?.club.name}. Vill du lägga till{' '}
            {dupe?.member.firstname} {dupe?.member.lastname} i klubben{' '}
            {club.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDupe(undefined);
            }}
          >
            Stäng
          </Button>
          <Button onClick={handleAddMembership}>Lägg till</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
