// import { UserManager } from 'oidc-client';
// import { AuthProvider } from 'oidc-react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';

import i18next from '../lib/i18n';
import { ResetPassword } from '../old/components';
import { UserContextProvider } from '../old/contexts';

export const Root: React.FC = ({ children }) => {
  // useEffect(() => {
  //   const userManager = new UserManager({
  //     authority: 'http://localhost:8010',
  //     client_id: 'y2c-app',
  //     redirect_uri: 'http://localhost:8000/cb',
  //     response_type: 'id_token',
  //     scope: 'openid profile',
  //   });

  //   (async () => {
  //     if (window.location.pathname === '/cb') {
  //       await userManager.signinRedirectCallback();
  //     }
  //     const user = await userManager.getUser();

  //     if (!user) {
  //       userManager.signinRedirect();
  //     }
  //   })();
  // }, []);

  return (
    // <AuthProvider
    //   userManager={userManager}
    //   // autoSignIn={false}
    // >
    <I18nextProvider i18n={i18next}>
      <CookiesProvider>
        <UserContextProvider>
          <ResetPassword />
          {children}
        </UserContextProvider>
      </CookiesProvider>
    </I18nextProvider>
    // </AuthProvider>
  );
};
