import axios from 'axios';
import { O } from 'ts-toolbelt';

export const memdbRequest = axios.create({
  baseURL: `${process.env.MEMDB_URL}/api/v2`,
  paramsSerializer: ({ schema, ...params }) =>
    Object.entries({ ...params, schema: JSON.stringify(schema) })
      .filter(([key, value]) => typeof value !== 'undefined')
      .map(
        ([key, value]) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(value as string),
      )
      .join('&'),
});

export type Schema<T> = {
  [K in keyof T]?: T[K] extends object ? Schema<T[K]> : number;
};

export type UnSchema<T, U> = T extends object
  ? {
      [K in keyof U]: UnSchema<O.At<T, K>, U[K]>;
    }
  : T;

export const Memdb = {
  TRUE: 1,
  FALSE: -1,
};
