import {
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import { Club, playingYear } from '@ssf/y2c-helpers';
import React, { useContext } from 'react';

import { ButtonRow, Loading, TextBubble } from '..';
import { UserContext, useRegStatus } from '../../contexts';
import { useMemdbRequest } from '../../hooks';

export function RegCreateUpdateClub({
  buttons,
  club,
  onNewEditedClub,
}: {
  buttons: React.ReactNode;
  club?: Club;
  onNewEditedClub(club: Club): void;
}) {
  const { spacing } = useTheme<Theme>();
  const { request, isLoading, errorMessage } = useMemdbRequest();
  const {
    status: { school },
  } = useRegStatus();

  const { user } = useContext(UserContext);

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement & { clubname: HTMLInputElement }>,
  ) => {
    event.preventDefault();

    if (school && user) {
      const name = event.currentTarget.clubname.value;
      if (club) {
        request<{ result: number }>({
          method: 'POST',
          url: `/club/${club.id}`,
          data: { club: { name } },
        }).then((response) => {
          if (response && response.data) {
            onNewEditedClub({ ...club, name });
          }
        });
      } else {
        const newClub: Omit<Club, 'id' | 'active'> = {
          name,
          street: school.street,
          zipcode: school.zipcode,
          city: school.city,
          noeconomy: 1,
          schoolclub: 1,
          schoolname: school.name,
          schoolid: school.id,
          countyid: school.countyid,
          vbdescr: `Deltar i Yes2Chess ${playingYear}`,
          schoolclubreason: 3, // yes2chess type
          phonenr: user.phone,
          email: user.email,
          startdate: new Date().toLocaleDateString('sv-SE'),
          startseason: `${new Date().getFullYear()}-01-01`,
          endseason: `${new Date().getFullYear()}-12-31`,

          registrations: [
            {
              member: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                birthday: user.birthday,
              },
              membershiptype: 130,
              fee: 10,
              date: new Date().toLocaleDateString('sv-SE'),
              regstart: `${new Date().getFullYear()}-01-01`,
              regend: `${new Date().getFullYear()}-12-31`,
            },
          ],
          roles: [],
        };

        const memberId = Number(
          new URLSearchParams(location.search).get('memberId'),
        );

        request<{ result: number }>({
          method: 'POST',
          url: '/club',
          data: {
            club: newClub,
            admin: !memberId,
            membership: !memberId,
            districtid: school.districtid,
          },
        }).then(async (response) => {
          if (response && response.data) {
            const id = response.data.result;

            const addRole = (orgrole: number) =>
              request<{ result: number }>({
                method: 'POST',
                url: `/member/${memberId || user.id}/role`,
                data: {
                  role: {
                    orgtype: 3,
                    orgrole,
                    start: new Date().toLocaleDateString('sv-SE'),
                    end: '1970-01-01',
                    orgid: id,
                    seclevel: -1,
                    memberid: memberId || user.id,
                    description: '',
                  },
                },
              });

            if (memberId) {
              await addRole(150);
              await request<{ result: number }>({
                url: `/club/${id}/membership/${memberId}`,
                method: 'POST',
              });
            }

            await addRole(107);

            onNewEditedClub({
              id,
              active: -1,
              ...newClub,
            });
          }
        });
      }
    }
  };

  return (
    <Container css={{ maxWidth: 600 + 24 * 2 }}>
      <TextBubble>
        När ni döper en klubb ska ni undvika att ha siffror, årtal eller
        klassbeteckningen i namnet. Ord som lag ska också undvikas. Låt gärna
        barnen rösta fram roliga klubbnamn!
      </TextBubble>
      <form onSubmit={handleSubmit}>
        <Card css={{ position: 'relative' }}>
          <CardContent>
            <TextField
              label="Klubbnamn"
              name="clubname"
              defaultValue={club ? club.name : ''}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
            ></TextField>
          </CardContent>
          {isLoading && <Loading />}
        </Card>
        <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {club ? 'Spara' : 'Skapa'}
          </Button>
          {buttons}
        </ButtonRow>
      </form>
    </Container>
  );
}
