import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SisLogo from '../assets/sis-logo.svg';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box mx={2} py={4} textAlign="center">
      <SisLogo css={{ height: 64 }} />
      <Typography variant="h2" css={{ fontWeight: 400 }}>
        {t('slogan')}
      </Typography>
    </Box>
  );
};
