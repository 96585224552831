import { Card, Container, Divider, Grid, Typography } from '@material-ui/core';
import { blue, green, grey, yellow } from '@material-ui/core/colors';
import {
  Gavel,
  People,
  Person,
  School,
  VerifiedUser,
} from '@material-ui/icons';
import { FileDocument } from 'mdi-material-ui';
import React, { useContext } from 'react';

import {
  BackButton,
  Loading,
  RegAccount,
  RegClubs,
  RegDocuments,
  RegOverviewButton,
  RegSchool,
  RegTeams,
  RegVerifications,
  TextBubble,
} from '..';
import { Faq } from '../../../components/Faq';
import { UserContext, useRegStatus } from '../../contexts';

export function getColor(status: number) {
  return [grey[500], yellow[800], blue[800], green[800]][status];
}

export function RegOverview() {
  const { user } = useContext(UserContext);
  const { isLoading, status, page, setPage } = useRegStatus();

  const buttons = (
    <BackButton
      onBack={() => {
        setPage(['overview']);
      }}
      css={{
        marginRight: 'auto',
      }}
    />
  );

  return (
    <Container
      css={{
        marginTop: 36 + 64,
        marginBottom: 36,
        maxWidth: 1040 + 24 * 2 + (page[0] === 'clubs' ? 100 : 0),
      }}
    >
      <Grid container spacing={2}>
        {page[0] === 'overview' && (
          <Grid item sm={8}>
            <TextBubble>
              Välkommen till översikten för din anmälan. Klicka på raderna under
              för att komma till respektive område. Du kan närsomhelst under
              anmälningstiden komma tillbaka hit och ändra på din anmälan.
            </TextBubble>
          </Grid>
        )}
        <Grid item sm={8}>
          {page[0] === 'account' ? (
            <RegAccount buttons={buttons} />
          ) : page[0] === 'school' ? (
            <RegSchool buttons={buttons} />
          ) : page[0] === 'clubs' ? (
            <RegClubs buttons={buttons} />
          ) : page[0] === 'teams' ? (
            <RegTeams buttons={buttons} />
          ) : page[0] === 'documents' ? (
            <RegDocuments buttons={buttons} />
          ) : page[0] === 'verifications' ? (
            <RegVerifications buttons={buttons} />
          ) : (
            <Card css={{ position: 'relative' }}>
              <RegOverviewButton
                name="Konto"
                Icon={Person}
                color={getColor(status.accountStatus.status)}
                message={status.accountStatus.message}
                onClick={() => setPage(['account'])}
              >
                <Typography variant="body1">
                  <b>Kontoinnehavare:</b> {user && user.name}
                </Typography>
                <Typography variant="body1">
                  <b>Användarnamn:</b> {user && user.email}
                </Typography>
              </RegOverviewButton>

              <Divider />

              <RegOverviewButton
                name="Skola"
                Icon={School}
                color={getColor(status.schoolStatus.status)}
                message={status.schoolStatus.message}
                onClick={() => setPage(['school'])}
              >
                <Typography variant="body1">
                  <b>Skola:</b>{' '}
                  {status.school ? status.school.name : <i>ingen vald</i>}
                </Typography>
              </RegOverviewButton>

              <Divider />

              <RegOverviewButton
                name="Klubbar"
                Icon={Gavel}
                color={getColor(status.clubsStatus.status)}
                message={status.clubsStatus.message}
                onClick={() =>
                  setPage(
                    status.clubs && status.clubs.length
                      ? ['clubs']
                      : ['clubs', 'club'],
                  )
                }
                disabled={status.clubsStatus.status === 0}
              >
                <Typography variant="body1">
                  <b>Klubbar:</b> {(status.clubs || []).length}
                </Typography>
              </RegOverviewButton>

              <Divider />

              <RegOverviewButton
                name="Anmälan"
                Icon={People}
                color={getColor(status.teamsStatus.status)}
                message={status.teamsStatus.message}
                onClick={() =>
                  setPage(
                    status.teams && status.teams.length
                      ? ['teams']
                      : ['teams', 'team'],
                  )
                }
                disabled={status.teamsStatus.status === 0}
              >
                <Typography variant="body1">
                  <b>Antal anmälda klubbar:</b> {(status.teams || []).length}
                </Typography>
                <Typography variant="body1">
                  <b>Antal anmälda spelare:</b>{' '}
                  {
                    (status.teams || []).flatMap(({ players }) => players)
                      .length
                  }
                </Typography>
              </RegOverviewButton>

              <Divider />

              <RegOverviewButton
                name="Dokument"
                Icon={FileDocument}
                color={getColor(status.documentsStatus.status)}
                message={status.documentsStatus.message}
                onClick={() => setPage(['documents'])}
                disabled={status.documentsStatus.status === 0}
              ></RegOverviewButton>

              <Divider />

              <RegOverviewButton
                name="Medlemsintygande"
                Icon={VerifiedUser}
                color={getColor(status.verificationsStatus.status)}
                message={status.verificationsStatus.message}
                onClick={() => setPage(['verifications'])}
                disabled={status.verificationsStatus.status === 0}
              ></RegOverviewButton>

              {(!user || isLoading) && <Loading />}
            </Card>
          )}
        </Grid>
        <Grid item sm={4}>
          <Faq tag={page.join('.')} />
        </Grid>
      </Grid>
    </Container>
  );
}
