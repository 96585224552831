export function debounce<T extends (...args: any[]) => any>(
  callback: T,
  wait = 200,
) {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  const callable = (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<ReturnType<T>>((resolve) => {
      timeoutId = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
  return callable;
}
