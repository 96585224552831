import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';

import { Text } from '.';

export type PromoArea = {
  type: 'promoArea';
  title: string;
  content: { text: string }[];
};

export const PromoArea: React.FC<{ data: PromoArea }> = ({ data }) => (
  <Box border={2} borderColor="primary.main" borderRadius={4}>
    <Box m={2}>
      <Typography variant="h2" color="primary">
        {data.title}
      </Typography>
    </Box>
    <Grid container>
      {data.content.map(({ text }) => (
        <Grid key={text} item xs={12} sm={6}>
          <Text data={{ type: 'text', text }} />
        </Grid>
      ))}
    </Grid>
  </Box>
);
